import { createApp } from "vue";
import App from "./App.vue";

import router from "./routes";
import store from "./store/store";
import DevUI from "vue-devui";
import "vue-devui/style.css";
import "@devui-design/icons/icomoon/devui-icon.css";
import SvgIcons from "@/components/SvgIcons.vue";

import "@/assets/styles/layout.css"; // 전역 CSS 파일 로드
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);
app.component("SvgIcons", SvgIcons); // 전역 컴포넌트로 등록
app.use(store);
app.use(router);
app.use(DevUI);
app.use(Toast);

app.mount("#app");
