//import Vue from "vue";
import { createStore } from "vuex";

//Vue.use(createStore);

const store = createStore({
  // 상태 데이터
  state() {
    return {
      userid: localStorage.getItem("userid") || "", // 초기 값을 localStorage에서 가져옴
      username: localStorage.getItem("username") || "", // 초기 값을 localStorage에서 가져옴
      usertype: localStorage.getItem("usertype") || "", // 초기 값을 localStorage에서 가져옴
      userpermission: localStorage.getItem("userpermission") || "", // 초기 값을 localStorage에서 가져옴
      companyid: localStorage.getItem("companyid") || "", // 초기 값을 localStorage에서 가져옴

      selectedMenu: "", // 현재 선택된 메뉴를 저장할 상태
    };
  },
  // 상태 변경 메서드
  mutations: {
    setUserid(state, userid) {
      state.userid = userid;
      localStorage.setItem("userid", userid);
    },
    setUsername(state, username) {
      state.username = username;
      localStorage.setItem("username", username); // localStorage에 저장
    },
    setUsertype(state, usertype) {
      state.usertype = usertype;
      localStorage.setItem("usertype", usertype); // localStorage에 저장
    },
    setUserpermission(state, userpermission) {
      state.userpermission = userpermission;
      localStorage.setItem("userpermission", userpermission); // localStorage에 저장
    },
    setcompanyid(state, companyid) {
      state.companyid = companyid;
      localStorage.setItem("companyid", companyid); // localStorage에 저장
    },
    setSelectedMenu(state, menu) {
      state.selectedMenu = menu; // 메뉴 상태를 업데이트하는 뮤테이션
    },
  },
  // 비동기 작업
  actions: {
    fetchUserId({ commit }) {
      const userid = localStorage.getItem("userid") || "이름오류";
      commit("setUserid", userid);
    },
    fetchUserName({ commit }) {
      const username = localStorage.getItem("username") || "이름오류";
      commit("setUsername", username);
    },
    fetchUsertype({ commit }) {
      const usertype = localStorage.getItem("usertype") || "X";
      commit("setUsertype", usertype);
    },
    fetchUserpermission({ commit }) {
      const userpermission = localStorage.getItem("userpermission") || "X";
      commit("setUserpermission", userpermission);
    },
    fetchcompanyid({ commit }) {
      const companyid = localStorage.getItem("companyid") || "X";
      commit("setcompanyid", companyid);
    },

    updateUserId({ commit }, userid) {
      commit("setUserid", userid);
    },
    updateUserName({ commit }, username) {
      commit("setUsername", username);
    },
    updateUserType({ commit }, usertype) {
      commit("setUsertype", usertype);
    },
    updateUserpermission({ commit }, userpermission) {
      commit("setUserpermission", userpermission);
    },
    updatecompanyid({ commit }, companyid) {
      commit("setcompanyid", companyid);
    },
    updateSelectedMenu({ commit }, menu) {
      commit("setSelectedMenu", menu); // 메뉴 상태를 업데이트하는 액션
    },
  },
  getters: {
    getUserid(state) {
      return state.userid;
    },
    getUsername(state) {
      return state.username;
    },
    getUsertype(state) {
      return state.usertype;
    },
    getUserpermission(state) {
      return state.userpermission;
    },
    getcompanyid(state) {
      return state.companyid;
    },
    getSelectedMenu(state) {
      return state.selectedMenu; // 메뉴 상태를 반환하는 getter
    },
  },
});

export default store;
