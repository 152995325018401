import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ProjectList from "@/views/projects/list.vue";
import ProjectAdd from "@/views/projects/add.vue";
import ProjectView from "@/views/projects/view.vue";
import ProjectCompanyList from "@/views/projects/companylist.vue";
import ProjectCompanyView from "@/views/projects/companyview.vue";

import ProjectMizeList from "@/views/projects/mizelist.vue";
import ProjectMizeAdd from "@/views/projects/mizeadd.vue";
import ProjectMizeView from "@/views/projects/mizeview.vue";

import OrdersList from "@/views/orders/list.vue";
import OrdersView from "@/views/orders/view.vue";
import OrdersMyList from "@/views/orders/self.vue";
import AccountingList from "@/views/accounting/list.vue";
import ManagersList from "@/views/managers/list.vue";
import ManagersAdd from "@/views/managers/add.vue";
import ManagersView from "@/views/managers/view.vue";
import NoticesList from "@/views/notices/list.vue";
import NoticesAdd from "@/views/notices/add.vue";
import NoticesView from "@/views/notices/view.vue";
import NoticesCompanyView from "@/views/notices/companyview.vue";
import MyInfoView from "@/views/myinfo/view.vue";
import Login from "@/views/login/login.vue"; // Login 페이지 추가
import SSORLogin from "@/views/login/ssologin.vue"; // ssologin.vue 파일 경로에 맞게 수정

const routes = [
  {
    path: "/login",
    component: Login, // Login 페이지를 경로로 설정
  },
  {
    path: "/ssologin/:userid/:type/:conid", // 파라미터로 userid를 받음
    component: SSORLogin,
    name: "SSOLogin",
  },
  {
    path: "/",
    redirect: "/login", // 기본 경로를 로그인 페이지로 리디렉션
  },
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "projects/list",
        component: ProjectList,
      },
      {
        path: "projects/add",
        component: ProjectAdd,
      },
      {
        path: "projects/view/:id",
        component: ProjectView,
        name: "ProjectView",
        props: true,
      },
      {
        path: "projects/companylist",
        component: ProjectCompanyList,
        name: "ProjectCompanyList",
        props: true,
      },
      {
        path: "projects/companyview/:id",
        component: ProjectCompanyView,
        name: "ProjectCompanyView",
        props: true,
      },
      {
        path: "projects/mizelist",
        component: ProjectMizeList,
      },
      {
        path: "projects/mizeadd",
        component: ProjectMizeAdd,
      },
      {
        path: "projects/mizeview/:id",
        component: ProjectMizeView,
        name: "ProjectMizeView",
        props: true,
      },
      {
        path: "orders/list",
        component: OrdersList,
      },
      {
        path: "orders/view/:id",
        component: OrdersView,
        name: "OrdersView",
        props: true,
      },
      {
        path: "orders/self",
        component: OrdersMyList,
      },
      {
        path: "accounting/list",
        component: AccountingList,
      },
      {
        path: "managers/list",
        component: ManagersList,
      },
      {
        path: "managers/add",
        component: ManagersAdd,
      },
      {
        path: "managers/view/:id",
        component: ManagersView,
        name: "ManagersView",
        props: true,
      },
      {
        path: "notices/list",
        component: NoticesList,
      },
      {
        path: "notices/add",
        component: NoticesAdd,
      },
      {
        path: "notices/view/:id",
        component: NoticesView,
        name: "NoticesView",
        props: true,
      },
      {
        path: "notices/companyview/:id",
        component: NoticesCompanyView,
        name: "NoticesCompanyView",
        props: true,
      },
      {
        path: "myinfo/view",
        component: MyInfoView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  // 인증이 필요하지 않은 페이지
  const publicPages = ["/login", "/ssologin"];

  // 로그인 여부 확인
  const loggedIn = localStorage.getItem("userid");

  // 현재 페이지가 publicPages에 포함되거나, ssologin 경로일 경우 인증을 요구하지 않음
  const isPublicPage =
    publicPages.includes(to.path) || to.path.startsWith("/ssologin");

  // 인증이 필요한 페이지이며 로그인이 되어 있지 않다면 로그인 페이지로 리디렉트
  if (!isPublicPage && !loggedIn) {
    return next("/login");
  }

  // 로그인이 되어 있거나 public 페이지일 경우, 다음 라우트로 진행
  next();
});

export default router;
