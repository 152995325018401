<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">프로젝트 상세보기 (마이즈)</div>
        <div class="description">
          프로젝트 상세보기 (마이즈) 을 조회하고 관리합니다.
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">프로젝트 상태</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>상태</th>
                  <td>
                    <div :class="getStatusClass(this.mizeStatus)">
                      <span>{{ getStatusText(this.mizeStatus) }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="part_title">프로젝트등록 유형</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트유형<span class="text_red">*</span></th>
                  <td>
                    <div v-if="mizeStatus === 'N'" class="inner_td">
                      <!-- 직접설치 - D , 사전답사 - P , 유지보수 -M -->
                      <button
                        type="button"
                        :class="{
                          on: projectType === 'D',
                        }"
                        @click="selectProjectType('D')"
                      >
                        설치공사(직접)
                      </button>
                      <button
                        type="button"
                        :class="{
                          on: projectType === 'P',
                        }"
                        @click="selectProjectType('P')"
                      >
                        사전답사
                      </button>
                      <button
                        type="button"
                        :class="{
                          on: projectType === 'M',
                        }"
                        @click="selectProjectType('M')"
                      >
                        유지보수
                      </button>
                    </div>
                    <div v-else>
                      <button
                        type="button"
                        class="on"
                        v-if="projectType === 'D'"
                      >
                        설치공사(직접)
                      </button>
                      <button
                        type="button"
                        class="on"
                        v-if="projectType === 'P'"
                      >
                        사전답사
                      </button>
                      <button
                        type="button"
                        class="on"
                        v-if="projectType === 'M'"
                      >
                        유지보수
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="projectType === 'M'" class="tbl_search tbl_info">
              <div class="part_title">유지보수정보</div>
              <table>
                <colgroup>
                  <col style="width: 18%" class="col_width26" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>유형<span class="text_red">*</span></th>
                    <td>
                      <div class="inner_td">
                        <!-- 원격 - R , 방문 - V  -->
                        <button
                          type="button"
                          :class="{
                            on: maintenanceType === 'R',
                          }"
                          @click="selectMaintenanceType('R')"
                        >
                          원격
                        </button>
                        <button
                          type="button"
                          :class="{
                            on: maintenanceType === 'V',
                          }"
                          @click="selectMaintenanceType('V')"
                        >
                          방문
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>결제형식<span class="text_red">*</span></th>
                    <td>
                      <div class="inner_td">
                        <!-- 유상 - O , 무상 - X  -->
                        <button
                          type="button"
                          :class="{
                            on: payType === 'O',
                          }"
                          @click="selectPayType('O')"
                        >
                          유상
                        </button>
                        <button
                          type="button"
                          :class="{
                            on: payType === 'X',
                          }"
                          @click="selectPayType('X')"
                        >
                          무상
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="payType === 'O'">
                    <th>결제비용<span class="text_red">*</span></th>
                    <td>
                      <input
                        type="text"
                        class="w_250 amount-input"
                        placeholder="결제 비용을 입력해주세요"
                        v-model="formattedRealAmount"
                        ref="formattedRealAmount"
                        @input="formatRealAmount"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="part_title">프로젝트정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트명<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="프로젝트명을 입력해주세요"
                      v-model="projectName"
                      ref="projectName"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
                <tr>
                  <th>지역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_250"
                        v-model="selectedArea"
                        @change="handleAreaChange"
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="area in areas"
                          :key="area.id"
                          :value="area.id"
                        >
                          {{ area.areaName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    날짜<br class="only_mobile" /><span class="text_red"
                      >*</span
                    >
                  </th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="date"
                        class="w_250"
                        v-model="selectedStartDate"
                        @change="addStartDate"
                      />

                      <div
                        v-for="(date, index) in selectedStartDates"
                        :key="index"
                        style="display: flex; align-items: center"
                      >
                        <label
                          :key="index"
                          :style="{
                            color: choiceSelDate === date ? 'red' : 'black', // 동일한 값일 경우 빨간색
                            fontWeight:
                              choiceSelDate === date ? 'bold' : 'normal', // 동일한 값일 경우 굵게 표시
                          }"
                        >
                          <input
                            type="radio"
                            :value="date"
                            :checked="true"
                            :disabled="
                              mizeStatus === 'YY' || mizeStatus === 'C'
                            "
                          />
                          {{ date }} ({{ getDayOfWeek(date) }})
                        </label>

                        <!-- X 버튼 추가 -->
                        <button
                          type="button"
                          @click="removeStartDate(index)"
                          style="margin-left: 10px; color: red"
                          v-if="mizeStatus === 'N'"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>시간<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <flat-pickr
                        v-model="selectedTime"
                        class="w_250"
                        :config="timePickerConfig"
                        placeholder="시간을 선택하세요"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ projectDetailLabel }}</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="상세설명을 입력하세요. "
                        v-model="detailDesc"
                        ref="detailDesc"
                        :disabled="mizeStatus === 'YY' || mizeStatus === 'C'"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr v-if="projectType === 'D'">
                  <th>투입자재목록<span class="text_red">*</span></th>
                  <td>
                    <button
                      type="button"
                      :class="{ on: !showMaterialDropdown }"
                      @click="toggleMaterialDropdown"
                      style="margin-bottom: 5px"
                      :disabled="mizeStatus === 'YY' || mizeStatus === 'C'"
                    >
                      투입자재 없음
                    </button>
                    <div class="inner_td" v-if="showMaterialDropdown">
                      <!-- 자재 선택 dropdown -->
                      <multiselect
                        v-model="selectedMaterial"
                        :options="materials"
                        :custom-label="customMaterialLabel"
                        track-by="id"
                        placeholder="자재를 검색하세요"
                        @select="addMaterial"
                        :searchable="true"
                        :close-on-select="true"
                        :allow-empty="false"
                        :disabled="mizeStatus === 'YY' || mizeStatus === 'C'"
                      />
                    </div>
                    <!-- 선택된 자재 목록 -->
                    <div
                      v-for="(material, index) in selectedMaterials"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{
                        material.materialsName +
                        (material.modelName
                          ? " (" + material.modelName + ")"
                          : "")
                      }}</span>
                      <div class="btns">
                        <button
                          type="button"
                          @click="decreaseQuantityByTen(index)"
                          v-show="mizeStatus === 'N'"
                        >
                          -10
                        </button>
                        <button
                          type="button"
                          @click="decreaseQuantity(index)"
                          v-show="mizeStatus === 'N'"
                        >
                          -1
                        </button>
                        <span class="quanity">{{ material.quantity }}</span>
                        <button
                          type="button"
                          @click="increaseQuantity(index)"
                          v-show="mizeStatus === 'N'"
                        >
                          +1
                        </button>
                        <button
                          type="button"
                          @click="increaseQuantityByTen(index)"
                          v-show="mizeStatus === 'N'"
                        >
                          +10
                        </button>
                      </div>
                      <button
                        type="button"
                        class="delbtn on"
                        @click="removeMaterial(index)"
                        v-show="mizeStatus === 'N'"
                      >
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="내용을 입력하세요. "
                        v-model="addMemo"
                        ref="addMemo"
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (영업)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="salesmanager in salesManagers"
                        :key="salesmanager.userId"
                        type="button"
                        :class="{
                          on: selectedSalesManager === salesmanager.userId,
                        }"
                        @click="selectSalesManager(salesmanager.userId)"
                        v-show="
                          selectedSalesManager === salesmanager.userId ||
                          (mizeStatus !== 'YY' && mizeStatus !== 'C')
                        "
                      >
                        {{ salesmanager.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (PM)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="pmmanager in pmManagers"
                        :key="pmmanager.userId"
                        type="button"
                        :class="{
                          on: selectedPmManagers.includes(pmmanager.userId),
                        }"
                        @click="
                          mizeStatus === 'N'
                            ? selectPmManager(pmmanager.userId)
                            : null
                        "
                        v-show="
                          selectedPmManagers.includes(pmmanager.userId) ||
                          (mizeStatus !== 'YY' && mizeStatus !== 'C')
                        "
                      >
                        {{ pmmanager.adminName }}
                      </button>
                      <span class="text_red">( * 여러인원 선택 가능 )</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">고객사 정보 (현장 정보)</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>고객사 상호<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="고객사 상호명을 입력해주세요"
                      v-model="customerName"
                      ref="customerName"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
                <tr>
                  <th>상세주소<span class="text_red">*</span></th>
                  <td class="td_address">
                    <div>
                      <input
                        type="text"
                        class="w_150"
                        placeholder="우편번호"
                        v-model="postalCode"
                        ref="postalCode"
                        readonly
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      />
                      <button
                        type="button"
                        class="keep"
                        @click="openPostcodePopup"
                        v-show="mizeStatus !== 'F' && mizeStatus !== 'C'"
                      >
                        주소찾기
                      </button>
                    </div>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="주소"
                      v-model="address"
                      ref="address"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                    <input
                      type="text"
                      class="w_250"
                      placeholder="상세주소"
                      v-model="detailedAddress"
                      ref="detailedAddress"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 이름<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="placemanagerName"
                      ref="placemanagerName"
                      placeholder="현장 담당자 이름을 입력해주세요"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 연락처<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="placemanagerMobile"
                      ref="placemanagerMobile"
                      @input="formatPhoneNumber"
                      placeholder="현장 담당자 연락처를 입력해주세요"
                      :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="btn_bottom_a">
            <button
              v-if="mizeStatus === 'N'"
              type="button"
              class="on"
              @click="checkChanges"
            >
              저장
            </button>
            <button type="button" @click="goToConstructionList">목록</button>
            <button
              v-if="mizeStatus === 'N'"
              type="button"
              @click="CompleteConstruction"
            >
              프로젝트완료
            </button>
            <button
              v-if="mizeStatus === 'N'"
              type="button"
              @click="checkCancel"
            >
              프로젝트취소
            </button>
          </div>
        </article>
      </div>
      <div style="margin-top: 10px"></div>
      <CommentSection :parameterId="this.id" :boardType="'프로젝트마이즈'" />
    </div>
  </div>
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isModalVisible ? 'block' : 'none' }"
    v-if="isModalVisible"
  >
    <div class="d_pop_box" style="width: 400px">
      <div class="d_pop_top">
        <div class="title">프로젝트 취소</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>사유</th>
                <td>
                  <textarea
                    placeholder="상세 사유를 입력해주세요. "
                    v-model="cancelMemo"
                    ref="cancelMemo"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="dpop_bottom_btns">
        <button type="button" @click="CancelConstruction">확인</button>
        <button type="button" @click="hideModal">취소</button>
      </div>
    </div>
  </div>
  <!-- 변경된 이유 입력 모달 -->
  <ReasonModal
    v-if="isModalVisible3"
    @close-modal="closeModal"
    :isModalVisible3="isModalVisible3"
    :showModalForFields="showModalForFields"
    :currentFieldIndex="currentFieldIndex"
    @submit-reason="submitChangeReason"
  />
</template>

<script>
/* global daum */
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import CommentSection from "@/components/comments/CommentSection.vue";
import ReasonModal from "@/components/comments/ReasonModal.vue"; // 모달 컴포넌트 import
import FlatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "ProjectMizeView",
  components: {
    Multiselect,
    CommentSection,
    ReasonModal,
    FlatPickr,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selectedTime: "", // 선택된 시간을 저장
      timePickerConfig: {
        enableTime: true,
        noCalendar: true, // 시간만 선택할 수 있게 설정
        dateFormat: "H:i", // 시간 형식 지정 (24시간 형식)
        time_24hr: true, // 24시간 형식 사용
      },
      showMaterialDropdown: true, // 자재 선택 dropdown을 표시할지 여부

      choiceSelDate: "", // 선택된 날짜 값
      selectedArea: "0", // 전체보기 기본 값 설정
      areas: [], // API에서 가져온 지역 목록을 저장할 배열
      materials: [], // 자재 목록을 저장할 배열
      selectedMaterial: null, // 선택된 자재를 저장할 변수
      selectedMaterials: [], // 선택된 자재 목록을 저장할 배열
      selectedStartDate: "", // 선택된 프로젝트 시작일
      selectedStartDates: [], // 선택된 프로젝트 시작일 목록
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      salesManagers: [], // 영업 담당자 목록을 저장할 배열
      selectedSalesManager: "", // 선택된 영업 담당자
      pmManagers: [], // PM 담당자 목록을 저장할 배열
      selectedPmManager: "", // 선택된 PM 담당자
      extraAmount: 0, // 실제 금액을 저장하는 변수
      formattedExtraAmount: "", // ','가 포함된 금액을 저장하는 변수
      formattedRealAmount: "", // ','가 포함된 금액을 저장하는 변수
      placemanagerMobile: "",
      placemanagerName: "",

      selectedBillDate: "",
      totalPersonnel: "", // 총 투입 예정 인원수를 저장하는 변수
      representativeName: "", // 프로젝트 담당자 이름을 저장하는 변수
      representativeContact: "", // 프로젝트 담당자 연락처를 저장하는 변수 choiceDtm: "",
      choiceCompanyId: "",
      choiceCompanyName: "",
      choiceUserId: "",
      choiceUserName: "",

      withDrawalDtm: "",
      withDrawalMemo: "",
      cancelDtm: "",
      cancelMemo: "",
      isModalVisible: false,
      isModalVisible2: false,

      // 기존 데이터
      originalData: {}, // fetchConstructionData()로 가져온 데이터를 저장

      // 변경된 항목에 대한 이유
      changeReasons: [], // 변경된 필드별 이유 저장
      showModalForFields: [], // 변경된 필드를 추적하여 모달창을 하나씩 띄우기 위한 배열
      isModalVisible3: false, // 모달창 표시 여부
      currentFieldIndex: 0, // 현재 모달창에서 보여줄 필드 인덱스

      selectedPmManagers: [],
      projectType: "",
      maintenanceType: "",
      payType: "",
      wrId: "",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    companyid() {
      return this.$store.getters.getcompanyid;
    },
    areaName() {
      // 지역 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.areas.find((area) => area.id === this.selectedArea)?.areaName ||
        "Unknown Area"
      );
    },
    mizeSalesUserName() {
      // 영업 담당자 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.salesManagers.find(
          (manager) => manager.userId === this.selectedSalesManager
        )?.adminName || "Unknown Sales Manager"
      );
    },
    mizePMUserName() {
      // PM 담당자 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.pmManagers.find(
          (manager) => manager.userId === this.selectedPmManager
        )?.adminName || "Unknown PM Manager"
      );
    },

    projectDetailLabel() {
      switch (this.projectType) {
        case "D":
          return "설치공사 상세내용";
        case "P":
          return "영업 상세내용";
        case "M":
          return "문의내용 상세내용";
        default:
          return "프로젝트 상세내용";
      }
    },
  },
  methods: {
    // 전화번호 포맷팅 함수
    formatPhoneNumber(event) {
      let input = event.target.value.replace(/\D/g, ""); // 숫자 이외의 문자 제거

      // 변환된 값을 담을 변수 선언
      let formattedNumber = "";

      if (input.length <= 3) {
        formattedNumber = input;
      } else if (input.length <= 7) {
        formattedNumber = input.slice(0, 3) + "-" + input.slice(3);
      } else {
        formattedNumber =
          input.slice(0, 3) +
          "-" +
          input.slice(3, 7) +
          "-" +
          input.slice(7, 11);
      }

      // 포맷팅된 값을 다시 반영
      this.placemanagerMobile = formattedNumber;
    },
    getStatusText(mizeStatus) {
      switch (mizeStatus) {
        case "N":
          return "진행중";
        case "YY":
          return "프로젝트완료";
        case "C":
          return "프로젝트취소";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(mizeStatus) {
      switch (mizeStatus) {
        case "N":
          return "stat back_green"; // 진행중
        case "YY":
          return "stat back_grey"; // 프로젝트완료
        case "C":
          return "stat back_lightgrey"; // 프로젝트취소
        default:
          return "stat back_default"; // 기본 값
      }
    },
    selectPayType(type) {
      this.payType = type;
    },
    selectMaintenanceType(type) {
      this.maintenanceType = type;
    },
    selectProjectType(type) {
      this.projectType = type; // 선택된 프로젝트 유형 업데이트
    },
    toggleMaterialDropdown() {
      this.showMaterialDropdown = !this.showMaterialDropdown; // 자재 선택 dropdown 보임/숨김 상태 전환

      if (!this.showMaterialDropdown) {
        // 자재 선택 dropdown을 숨길 때 자재 목록 초기화
        this.selectedMaterials = [];
        this.selectedMaterial = null;
      }
    },
    formatRealAmount(event) {
      // 입력된 값에서 숫자만 추출
      const rawValue = event.target.value.replace(/[^0-9]/g, "");

      // 숫자를 실제 금액 변수에 저장
      this.realAmount = parseInt(rawValue, 10) || 0;

      // 천 단위로 ',' 추가
      this.formattedRealAmount = this.realAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    closeModal() {
      this.isModalVisible3 = false; // 모달을 닫기 위해 false로 설정
    },
    // 기존 데이터와 입력 데이터를 비교하는 메서드
    checkChanges() {
      this.showModalForFields = [];

      if (this.originalData.projectType !== this.projectType) {
        this.showModalForFields.push("프로젝트유형");
      }

      if (this.originalData.maintenanceType !== this.maintenanceType) {
        this.showModalForFields.push("유지보수(유형)");
      }

      if (this.originalData.payType !== this.payType) {
        this.showModalForFields.push("유지보수(결제형식)");
      }

      if (this.originalData.price !== this.formattedRealAmount) {
        this.showModalForFields.push("유지보수(결제비용)");
      }

      if (this.originalData.projectName !== this.projectName) {
        this.showModalForFields.push("프로젝트명");
      }

      if (this.originalData.areaId !== this.selectedArea) {
        this.showModalForFields.push("지역");
      }

      if (this.originalData.startDate !== this.selectedStartDates.join("^")) {
        this.showModalForFields.push("날짜");
      }

      if (this.originalData.choiceSelDate !== this.choiceSelDate) {
        this.showModalForFields.push("날짜");
      }

      if (this.originalData.startTime !== this.selectedTime) {
        this.showModalForFields.push("시간");
      }

      if (this.originalData.detailContent !== this.detailDesc) {
        this.showModalForFields.push("상세내용");
      }

      // 자재 목록 및 수량 비교
      const originalMaterials = this.originalData.mizeMaterials.map((mat) => ({
        id: mat.materialId,
        quantity: mat.materialCnt, // 수량도 포함
      }));

      const updatedMaterials = this.selectedMaterials.map((mat) => ({
        id: mat.id,
        quantity: mat.quantity, // 현재 변경된 수량도 포함
      }));

      // 자재 목록 또는 수량이 다르면 비교
      if (
        originalMaterials.length !== updatedMaterials.length ||
        !originalMaterials.every(
          (origMat, index) =>
            origMat.id === updatedMaterials[index].id &&
            origMat.quantity === updatedMaterials[index].quantity
        )
      ) {
        this.showModalForFields.push("투입자재목록(수량)");
      }

      if (this.originalData.etcMemo !== this.addMemo) {
        this.showModalForFields.push("특이사항");
      }

      if (this.originalData.mizeSalesUserId !== this.selectedSalesManager) {
        this.showModalForFields.push("담당자 (영업)");
      }

      // this.selectedPmManagers = this.constructionInfo[0].mizePMUserId;
      if (this.originalData.mizePMUserId !== this.selectedPmManagers) {
        this.showModalForFields.push("담당자 (PM)");
      }

      if (this.originalData.customerName !== this.customerName) {
        this.showModalForFields.push("고객사 상호");
      }

      if (this.originalData.zipCode !== this.postalCode) {
        this.showModalForFields.push("우편번호");
      }

      if (this.originalData.addr2 !== this.detailedAddress) {
        this.showModalForFields.push("상세주소");
      }

      if (this.originalData.placeManagerName !== this.placemanagerName) {
        this.showModalForFields.push("현장 담당자 이름");
      }

      if (this.originalData.placeManagerMobile !== this.placemanagerMobile) {
        this.showModalForFields.push("현장 담당자 연락처");
      }

      if (this.showModalForFields.length > 0) {
        this.isModalVisible3 = true;
        this.currentFieldIndex = 0;
      } else {
        // 변경된 필드가 없으면 바로 저장
        this.saveConstruction();
      }
    },
    // 현재 모달창에서 이유를 입력하고 다음 필드로 넘어가는 메서드
    submitChangeReason(reason) {
      const field = this.showModalForFields[this.currentFieldIndex];
      let originval = "";
      let newval = "";

      if (this.mizeStatus === "N") {
        /*
      
          this.projectType = this.constructionInfo[0].projectType;
          this.maintenanceType = this.constructionInfo[0].maintenanceType;
          this.payType = this.constructionInfo[0].payType;
          this.formattedRealAmount = this.constructionInfo[0].price;
          this.selectedTime = this.constructionInfo[0].startTime;
      */

        if (field === "프로젝트유형") {
          originval = this.originalData.projectType;
          newval = this.projectType;
        }
        if (field === "유지보수(유형)") {
          originval = this.originalData.maintenanceType;
          newval = this.maintenanceType;
        }
        if (field === "유지보수(결제형식)") {
          originval = this.originalData.payType;
          newval = this.payType;
        }
        if (field === "유지보수(결제비용)") {
          originval = this.originalData.price;
          newval = this.formattedRealAmount;
        }

        if (field === "프로젝트명") {
          originval = this.originalData.projectName;
          newval = this.projectName;
        }
        if (field === "프로젝트금액") {
          originval = this.originalData.price;
          newval = this.formattedRealAmount;
        }
        if (field === "추가금액") {
          originval = this.originalData.addPrice
            ? this.originalData.addPrice
            : 0;
          newval = this.formattedExtraAmount;
        }
        if (field === "지역") {
          // originalData의 지역 이름 찾기
          const originalArea = this.areas.find(
            (area) => area.id === this.originalData.areaId
          );
          const originalAreaName = originalArea
            ? originalArea.areaName
            : "데이터 없음";

          // 선택된 지역 이름 찾기
          const selectedArea = this.areas.find(
            (area) => area.id === this.selectedArea
          );
          const selectedAreaName = selectedArea
            ? selectedArea.areaName
            : "데이터 없음";

          originval = originalAreaName;
          newval = selectedAreaName;
        }

        if (field === "날짜") {
          originval = this.originalData.startDate.replace(/\^/g, ",");
          newval = this.selectedStartDates;
        }

        if (field === "날짜") {
          originval = this.originalData.choiceSelDate;
          newval = this.choiceSelDate;
        }
        if (field === "시간") {
          originval = this.originalData.startTime;
          newval = this.selectedTime;
        }

        if (field === "프로젝트상세내용") {
          originval = this.originalData.detailContent;
          newval = this.detailDesc;
        }
        if (field === "투입자재목록(수량)") {
          // 기존 자재 목록과 수량을 문자열로 만듦
          const originalMaterialsString = this.originalData.mizeMaterials
            .map((mat) => `${mat.materialName} (${mat.materialCnt}개)`)
            .join(", ");

          // 수정된 자재 목록과 수량을 문자열로 만듦
          const updatedMaterialsString = this.selectedMaterials
            .map((mat) => `${mat.materialsName} (${mat.quantity}개)`)
            .join(", ");

          originval = `${originalMaterialsString}`;
          newval = `${updatedMaterialsString}`;
        }

        if (field === "특이사항") {
          originval = this.originalData.etcMemo;
          newval = this.addMemo;
        }
        if (field === "담당자 (영업)") {
          // originalData의 영업 담당자 이름 찾기
          const originalSalesManager = this.salesManagers.find(
            (manager) => manager.userId === this.originalData.mizeSalesUserId
          );
          const originalSalesManagerName = originalSalesManager
            ? originalSalesManager.adminName
            : "데이터 없음";

          // 선택된 영업 담당자 이름 찾기
          const selectedSalesManager = this.salesManagers.find(
            (manager) => manager.userId === this.selectedSalesManager
          );
          const selectedSalesManagerName = selectedSalesManager
            ? selectedSalesManager.adminName
            : "데이터 없음";

          originval = originalSalesManagerName;
          newval = selectedSalesManagerName;
        }

        // // this.selectedPmManagers = this.constructionInfo[0].mizePMUserId;
        // if (this.originalData.mizePMUserId !== this.selectedPmManagers) {
        //   this.showModalForFields.push("담당자 (PM)");
        // }

        // this.selectedPmManagers = this.constructionInfo[0].mizePMUserId;
        console.log("♥====" + this.originalData.mizePMUserId);
        console.log("♥====" + this.selectedPmManagers);

        if (field === "담당자 (PM)") {
          // Helper function to find names for multiple IDs
          const getManagerNames = (ids) => {
            return ids
              .split("^") // Split the IDs by '^'
              .map((id) => {
                const manager = this.pmManagers.find(
                  (manager) => manager.userId === id
                );
                return manager ? manager.adminName : "데이터 없음";
              })
              .join(", "); // Join names with ','
          };

          // Get names for original PMs
          const originalPmManagerNames = getManagerNames(
            this.originalData.mizePMUserId
          );

          // Get names for selected PMs
          const selectedPmManagerNames = getManagerNames(
            this.selectedPmManagers
          );

          originval = originalPmManagerNames;
          newval = selectedPmManagerNames;
        }

        if (field === "고객사 상호") {
          originval = this.originalData.customerName;
          newval = this.customerName;
        }

        if (field === "우편번호") {
          originval = this.originalData.zipCode + " " + this.originalData.addr1;
          newval = this.postalCode + " " + this.address;
        }

        if (field === "상세주소") {
          originval = this.originalData.addr2;
          newval = this.detailedAddress;
        }

        if (field === "현장 담당자 이름") {
          originval = this.originalData.placeManagerName;
          newval = this.placemanagerName;
        }

        if (field === "현장 담당자 연락처") {
          originval = this.originalData.placeManagerMobile;
          newval = this.placemanagerMobile;
        }
      }

      // 배열에 { fieldName, reason } 객체를 추가
      this.changeReasons.push({
        ItemName: field,
        OriginValue: originval,
        NewValue: newval,
        Reason: reason,
      });

      // 다음 필드로 이동
      this.currentFieldIndex++;

      // 모든 필드를 처리했을 때
      if (this.currentFieldIndex >= this.showModalForFields.length) {
        this.isModalVisible3 = false;
        this.saveConstruction();
      }
    },
    customMaterialLabel(option) {
      return `${option.materialsName} ${
        option.modelName ? `(${option.modelName})` : ""
      }`;
    },
    showModal2() {
      this.isModalVisible2 = true;
    },
    hideModal2() {
      this.isModalVisible2 = false;
    },
    getDayOfWeek(dateString) {
      //console.log("Received dateString:", dateString);
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.log("Invalid date:", dateString);
        return "유효하지 않은 날짜";
      }
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      const day = date.getDay();
      return daysOfWeek[day];
    },

    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
    },
    // selectPmManager(pmmanagerId) {
    //   this.selectedPmManager = pmmanagerId;
    // },
    selectPmManager(pmmanagerId) {
      // 문자열을 배열로 변환
      let selectedPmArray = this.selectedPmManagers.split("^");

      if (pmmanagerId === "userid") {
        // '미지정'이 선택되면 다른 PM 선택을 모두 해제
        selectedPmArray = ["userid"];
      } else {
        // '미지정' 선택을 해제
        const noneIndex = selectedPmArray.indexOf("userid");
        if (noneIndex > -1) {
          selectedPmArray.splice(noneIndex, 1);
        }

        const index = selectedPmArray.indexOf(pmmanagerId);

        // 이미 선택된 PM이면 해제, 선택되지 않은 PM이면 추가
        if (index > -1) {
          // 이미 선택된 경우 해제
          selectedPmArray.splice(index, 1);
        } else {
          // 선택된 PM 추가
          selectedPmArray.push(pmmanagerId);
        }
      }

      // 배열을 다시 '^'로 결합하여 문자열로 변환
      this.selectedPmManagers = selectedPmArray.join("^");
    },
    async fetchPmManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/pm-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.pmManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.pmManagers);
        }
      } catch (error) {
        console.error("Error fetching pm managers:", error);
      }
    },
    selectSalesManager(salesmanagerId) {
      this.selectedSalesManager = salesmanagerId;
    },
    async fetchSalesManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/sales-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.salesManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.salesManagers);
        }
      } catch (error) {
        console.error("Error fetching sales managers:", error);
      }
    },
    getFormattedDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    // 프로젝트 시작일 추가
    addStartDate(event) {
      this.checkdatedevice++;
      const dateValue = event.target.value;
      const today = this.getFormattedDate();

      if (this.selectedStartDates.length >= 1) {
        this.toast.error("프로젝트 시작일은 한 개만 선택 가능합니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 1.5초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        this.selectedStartDate = "";
        return; // 날짜 추가 중지
      }

      if (this.selectedStartDate) {
        // 이미 선택된 날짜가 있는지 확인
        const exists = this.selectedStartDates.includes(this.selectedStartDate);
        if (dateValue >= today) {
          if (!exists) {
            // 선택된 날짜가 없으면 추가
            this.selectedStartDates.push(this.selectedStartDate); // 기존 선택된 날짜는 유지하고 새로운 날짜는 선택되지 않도록 설정
            this.choiceSelDate = this.choiceSelDate || "";
            // 추가 후 날짜를 빠른 순으로 정렬
            this.selectedStartDates.sort((a, b) => new Date(a) - new Date(b));
          } else {
            this.toast.error("이미 선택된 프로젝트 시작일입니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }

          this.selectedStartDate = "";
        }
      } else {
        console.log(this.checkdatedevice);
        this.toast.error("프로젝트 등록 시 과거 날짜는 선택할 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        // 선택 후 초기화
        this.selectedStartDate = "";
      }
    },
    // 프로젝트 시작일 제거
    removeStartDate(index) {
      this.selectedStartDates.splice(index, 1);
    },
    async fetchMaterials() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Materials/all-materials-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.materials = Array.isArray(data.data) ? data.data : [];
          console.log(this.materials);
        }
      } catch (error) {
        console.error("Error fetching materials:", error);
      }
    },
    addMaterial() {
      if (this.selectedMaterial) {
        // 선택된 자재가 이미 목록에 없으면 추가
        const exists = this.selectedMaterials.find(
          (mat) => mat.id === this.selectedMaterial.id
        );
        if (!exists) {
          this.selectedMaterials.push({
            ...this.selectedMaterial,
            quantity: 1, // 기본 수량 1로 설정
          });
          // 선택 후 드롭다운 초기화
          this.selectedMaterial = null;
        } else {
          this.toast.error("이미 선택된 자재입니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.selectedMaterial = "";
        }
      }
    },
    increaseQuantity(index) {
      this.selectedMaterials[index].quantity =
        parseInt(this.selectedMaterials[index].quantity, 10) + 1;
    },
    // 수량 10 증가 메소드
    increaseQuantityByTen(index) {
      this.selectedMaterials[index].quantity =
        parseInt(this.selectedMaterials[index].quantity, 10) + 10;
    },

    // 수량 10 감소 메소드
    decreaseQuantityByTen(index) {
      const currentQuantity = parseInt(
        this.selectedMaterials[index].quantity,
        10
      );
      if (currentQuantity > 10) {
        this.selectedMaterials[index].quantity = currentQuantity - 10;
      } else {
        this.toast.error("현재 10 이하의 수량으로 더 이상 줄일 수 없습니다.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    },
    // 수량 1 감소 메소드
    decreaseQuantity(index) {
      const currentQuantity = parseInt(
        this.selectedMaterials[index].quantity,
        10
      );
      if (currentQuantity > 1) {
        this.selectedMaterials[index].quantity = currentQuantity - 1;
      } else {
        this.toast.error("1개 미만 수량으로 선택할 수 없습니다.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    },
    removeMaterial(index) {
      this.selectedMaterials.splice(index, 1);
    },
    handleAreaChange() {
      this.currentPage = 1; // 지역 변경 시 페이지를 1로 리셋
      //this.fetchData(); // 지역 선택 시 데이터 갱신
    },
    goToConstructionList() {
      this.$router.push({ path: "/projects/mizelist" });
    },
    async fetchAreas() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Area/all-area-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.areas = Array.isArray(data.data) ? data.data : [];
          console.log(this.areas);
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    },
    async checkCancel() {
      const confirmation = confirm("해당 프로젝트를 취소 처리를 하시겠습니까?");
      if (confirmation) {
        this.isModalVisible = true;
      }
    },
    async CancelConstruction() {
      try {
        // 수주 취소 처리를 위한 API 호출
        const response = await apiClient.post(
          "/api/MizeConstruction/construction-cancel-update",
          {
            Id: this.id, // 프로젝트 ID 전달
            Status: "C", // 프로젝트 취소 상태로
            cancelMemo: this.cancelMemo,
            LoginId: localStorage.getItem("userid"), // 로그인 사용자 ID
            WrId: this.wrId,
          }
        );

        // 응답이 성공적일 경우
        if (response.status === 200) {
          this.toast.success("프로젝트 취소 처리되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          //this.goToConstructionList();
          const randomString = new Date().getTime();

          this.$router.replace({
            path: "/projects/mizeview/" + this.id,
            query: { reload: randomString },
          });
        } else {
          this.toast.error("프로젝트 취소 처리에 실패하였습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      } catch (error) {
        console.error("Error during cancel construction:", error);
        this.toast.error(
          "오류가 발생했습니다. 프로젝트 취소 처리가 실패했습니다.",
          {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          }
        );
      }
    },
    async CompleteConstruction() {
      // 사용자에게 프로젝트 완료 확인을 요청
      const userConfirmed = confirm("프로젝트 완료 처리를 하시겠습니까?");

      // 사용자가 확인 버튼을 눌렀을 경우 API 호출
      if (userConfirmed) {
        try {
          // 프로젝트 완료 처리를 위한 API 호출
          const response = await apiClient.post(
            "/api/MizeConstruction/construction-complete",
            {
              Id: this.id, // 프로젝트 ID 전달
              Status: "YY", // 프로젝트완료 상태로 설정
              LoginId: localStorage.getItem("userid"), // 로그인 사용자 ID
            }
          );

          // 응답이 성공적일 경우
          if (response.status === 200) {
            this.toast.success("프로젝트 완료 처리되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            //this.goToConstructionList();
            const randomString = new Date().getTime();

            this.$router.replace({
              path: "/projects/mizeview/" + this.id,
              query: { reload: randomString },
            });
          } else {
            this.toast.error("프로젝트 완료 처리에 실패하였습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }
        } catch (error) {
          console.error("Error during complete construction:", error);
          this.toast.error(
            "오류가 발생했습니다. 프로젝트 완료 처리가 실패했습니다.",
            {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            }
          );
        }
      }
    },
    async saveConstruction() {
      if (this.checkFields()) {
        // 프로젝트 시작일을 '^'로 구분된 문자열로 변환
        const formattedStartDates = this.selectedStartDates.join("^");

        const changeReasons = this.changeReasons.map((reason) => {
          // 만약 NewValue가 배열이라면 문자열로 변환
          if (Array.isArray(reason.NewValue)) {
            return {
              ...reason,
              NewValue: reason.NewValue.join("^"), // 배열을 문자열로 변환
            };
          }
          return reason;
        });

        console.log("★--this.areaName--" + this.areaName);
        console.log("★--this.mizeSalesUserName--" + this.mizeSalesUserName);

        const pmManagerIds = Array.isArray(this.selectedPmManagers)
          ? this.selectedPmManagers.join("^")
          : this.selectedPmManagers;

        const pmManagerNames = this.pmManagers
          .filter((pm) => this.selectedPmManagers.includes(pm.userId))
          .map((pm) => pm.adminName)
          .join("^");

        try {
          const response = await apiClient.post(
            "/api/MizeConstruction/construction-edit",
            {
              Id: this.id,
              ProjectName: this.projectName,
              StartDate: formattedStartDates,
              StartTime: this.selectedTime,
              ChoiceSelDate: this.choiceSelDate,
              AreaId: this.selectedArea,
              AreaName: this.areaName,
              ZipCode: this.postalCode,
              Addr1: this.address,
              Addr2: this.detailedAddress,
              DetailContent: this.detailDesc,
              MizeMaterials: this.selectedMaterials.map((mat) => ({
                MaterialId: mat.id,
                MaterialCnt: mat.quantity,
                MaterialName: mat.materialsName,
              })),
              EtcMemo: this.addMemo,
              ChoiceCompanyId: this.choiceCompanyId,
              ChoiceCompanyName: this.choiceCompanyName,
              ChoiceUserId: this.choiceUserId,
              ChoiceUserName: this.choiceUserName,
              managerUserName: this.representativeName,
              managerUserPhone: this.representativeContact,
              TotalPeople: this.totalPersonnel,
              MizeSalesUserId: this.selectedSalesManager,
              MizePMUserId: pmManagerIds, // PM 담당자 ID '^'로 구분
              MizePMUserName: pmManagerNames, // PM 담당자 이름 '^'로 구분

              MizeSalesUserName: this.mizeSalesUserName,

              CustomerName: this.customerName,
              LoginId: localStorage.getItem("userid"),
              // 필요한 다른 필드 추가
              MizeChangeReasons: changeReasons, //this.changeReasons, // 변경 사유 추가

              PlaceManagerMobile: this.placemanagerMobile,
              PlaceManagerName: this.placemanagerName,
              MaterialsNoYN: this.showMaterialDropdown == true ? "N" : "Y",
              ProjectType: this.projectType,

              MaintenanceType: this.maintenanceType,
              PayType: this.payType,
              Price: this.formattedRealAmount,
              WrId: this.wrId,
            }
          );
          if (response.status === 200) {
            this.toast.success("프로젝트 정보가 성공적으로 수정되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            //this.goToConstructionList();
            const randomString = new Date().getTime();

            this.$router.replace({
              path: "/projects/mizeview/" + this.id,
              query: { reload: randomString },
            });
          }
        } catch (error) {
          console.error("Error saving construction data:", error);
          this.toast.error("프로젝트 정보가 수정 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    async fetchConstructionData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post(
          "/api/MizeConstruction/construction-info",
          {
            Id: this.id,
            LoginId: localStorage.getItem("userid"),
          }
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.originalData = { ...data.data[0] }; // 첫 번째 데이터만 복사해 저장

          this.constructionInfo = Array.isArray(data.data) ? data.data : [];
          console.log(
            "☆☆☆☆☆☆☆☆this.constructionInfo[0]☆☆☆☆☆☆☆☆☆" +
              this.constructionInfo[0]
          );

          this.mizeStatus = this.constructionInfo[0].mizeStatus; // 추가: 상태값 저장
          this.projectName = this.constructionInfo[0].projectName;

          this.selectedArea = this.constructionInfo[0].areaId;
          // 시작일 부분 split 해서 ui 표현해야 함
          this.formattedStartDates = this.constructionInfo[0].startDate;
          this.selectedStartDates = this.formattedStartDates.split("^"); // 날짜를 배열로 변환

          this.postalCode = this.constructionInfo[0].zipCode;
          this.address = this.constructionInfo[0].addr1;
          this.detailedAddress = this.constructionInfo[0].addr2;
          this.detailDesc = this.constructionInfo[0].detailContent;

          this.choiceSelDate = data.data[0].choiceSelDate; // 프로젝트 시작일 선택 값 설정
          //this.selectedMaterials = this.constructionInfo[0].

          this.addMemo = this.constructionInfo[0].etcMemo;
          this.selectedSalesManager = this.constructionInfo[0].mizeSalesUserId;
          //this.selectedPmManager = this.constructionInfo[0].mizePMUserId;

          this.selectedPmManagers = this.constructionInfo[0].mizePMUserId;
          this.customerName = this.constructionInfo[0].customerName;
          this.totalPersonnel = this.constructionInfo[0].totalPeople;
          this.representativeName = this.constructionInfo[0].managerUserName;
          this.representativeContact =
            this.constructionInfo[0].managerUserPhone;

          this.selectedBillDate = this.constructionInfo[0].billDate;
          this.choiceDtm = this.constructionInfo[0].choiceDtm;
          this.choiceCompanyId = this.constructionInfo[0].choiceCompanyId;
          this.choiceCompanyName = this.constructionInfo[0].choiceCompanyName;
          this.choiceUserId = this.constructionInfo[0].choiceUserId;
          this.choiceUserName = this.constructionInfo[0].choiceUserName;

          this.placemanagerMobile = this.constructionInfo[0].placeManagerMobile;
          this.placemanagerName = this.constructionInfo[0].placeManagerName;
          this.showMaterialDropdown =
            this.constructionInfo[0].materialsNoYN === "Y" ? false : true;

          this.cancelDtm = this.constructionInfo[0].canceledDtm;
          this.cancelMemo = this.constructionInfo[0].cancelMemo;

          this.projectType = this.constructionInfo[0].projectType;
          this.maintenanceType = this.constructionInfo[0].maintenanceType;
          this.payType = this.constructionInfo[0].payType;
          this.formattedRealAmount = this.constructionInfo[0].price;
          this.selectedTime = this.constructionInfo[0].startTime;
          this.wrId = this.constructionInfo[0].wrId;

          // 프로젝트 정보에 자재 목록이 포함되어 있는 경우 처리
          if (this.constructionInfo[0].mizeMaterials) {
            this.selectedMaterials = this.constructionInfo[0].mizeMaterials.map(
              (material) => ({
                id: material.materialId,
                materialsName: material.materialName,
                modelName: material.modelName || "", // 모델명이 있을 경우 포함
                quantity: material.materialCnt || 1, // 수량 정보가 없으면 기본 수량 1
              })
            );
          }
        }
      } catch (error) {
        console.error("Error fetching constructionInfo data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    checkFields() {
      if (!this.projectType) {
        // 프로젝트 유형이 선택되지 않았을 때
        this.toast.error("프로젝트 유형을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (this.projectType === "M") {
        // 유형, 결제형식, 결제비용 세개 체크
        if (!this.maintenanceType) {
          this.toast.error("유지보수 형식을 선택해주세요.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          return false;
        }

        // 유형, 결제형식, 결제비용 세개 체크
        if (!this.payType) {
          this.toast.error("유지보수 결제 형식을 선택해주세요.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          return false;
        }

        // 결제 형식이 유상일 경우 비용 체크
        if (this.payType === "O") {
          if (!this.formattedRealAmount) {
            this.$refs.formattedRealAmount.focus();
            this.toast.error("결제비용을 입력해주세요.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });

            return false;
          }
        }
      }

      if (!this.projectName) {
        this.$refs.projectName.focus();
        this.toast.error("프로젝트명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (this.selectedArea === "0") {
        // 전체보기 (기본값) 또는 선택되지 않은 상태
        this.toast.error("지역을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (this.selectedStartDates.length === 0) {
        // 프로젝트 시작일이 선택되지 않았을 때
        this.toast.error("프로젝트 시작일을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (this.projectType === "D") {
        if (this.showMaterialDropdown && this.selectedMaterials.length === 0) {
          // 투입자재목록이 선택되지 않았을 때
          this.toast.error("투입자재목록을 선택해주세요.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          return false;
        }
      }

      //   if (!this.constructionManager) {
      //     // 담당자(시공) 항목이 선택되지 않았을 때
      //     this.$refs.constructionManager.focus();
      //     this.toast.error("담당자(시공)를 입력해주세요.", {
      //       position: "top-center", // 메시지 위치
      //       timeout: 1500, // 3초 동안 표시
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       hideProgressBar: true, // 진행 표시줄 숨기기
      //       closeButton: false, // X 버튼 숨기기
      //     });
      //     return false;
      //   }

      if (!this.selectedSalesManager) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        this.toast.error("담당자(영업)를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      // selectedPmManager
      if (!this.selectedPmManagers) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        this.toast.error("담당자(PM)를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.customerName) {
        // 고객사 상호가 입력되지 않았을 때
        this.$refs.customerName.focus();
        this.toast.error("고객사 상호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.detailedAddress) {
        this.$refs.detailedAddress.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("상세주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.placemanagerName) {
        this.$refs.placemanagerName.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("현장 담당자 이름을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.placemanagerMobile) {
        this.$refs.placemanagerMobile.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("현장 담당자 연락처를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      return true;
    },
    openPostcodePopup() {
      new daum.Postcode({
        oncomplete: (data) => {
          this.postalCode = data.zonecode;
          this.address = data.address;
          this.$refs.detailedAddress.focus();
        },
      }).open();
    },
  },
  mounted() {
    this.fetchAreas(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchMaterials(); // 자재 데이터를 가져오기
    this.fetchSalesManagers(); // 영업 담당자 목록을 가져옴
    this.fetchPmManagers(); // 영업 담당자 목록을 가져옴
    this.fetchConstructionData(); // 프로젝트 상세정보
  },
};
</script>

<style scoped>
/* 기본 입력 필드 오른쪽 정렬 */
input.amount-input {
  text-align: right; /* 입력값은 오른쪽 정렬 */
}

/* placeholder 왼쪽 정렬 */
input.amount-input::placeholder {
  text-align: left; /* placeholder는 왼쪽 정렬 */
}
.multiselect__input {
  position: relative;
  z-index: 1;
}

.multiselect__content {
  position: absolute;
  top: calc(100% + 5px); /* 입력 필드 바로 아래로 위치시킴 */
  z-index: 9999;
}

.flatpickr-time {
  width: 120px; /* 시간 선택기의 전체 너비 제한 */
}

.flatpickr-time .flatpickr-time-separator {
  margin: 0 5px; /* 시간과 분 사이 간격 조절 */
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  /* width: 307.875px; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}
</style>
