<template>
  <div class="comment-section">
    <h3>댓글</h3>

    <div class="comment-list">
      <RecursiveComment
        v-for="comment in comments"
        :key="comment.commentId"
        :comment="comment"
        @submit-reply="addReply"
        @submit-reply-success="fetchComments"
      />
    </div>

    <div class="new-comment">
      <textarea
        v-model="newCommentContent"
        placeholder="작성하실 댓글 내용을 입력해주세요."
      ></textarea>
      <div class="submit-button">
        <button @click="submitComment">댓글작성</button>
      </div>
    </div>
  </div>
</template>

<script>
import RecursiveComment from "./RecursiveComment.vue";
import apiClient from "@/apiClient";

export default {
  name: "CommentSection",
  components: {
    RecursiveComment,
  },
  props: {
    parameterId: {
      type: String,
      required: true, // 댓글을 불러올 유니크한 ID parameterId
    },
    boardType: {
      type: String, // boardType String 타입으로 설정
      required: true,
    },
  },
  data() {
    return {
      newCommentContent: "",
      comments: [],
    };
  },
  async mounted() {
    await this.fetchComments();
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    companyid() {
      return this.$store.getters.getcompanyid;
    },
  },
  methods: {
    async fetchComments() {
      try {
        // 예시 API 호출 (실제 API 엔드포인트에 맞게 수정 필요)
        const response = await apiClient.post("/api/Comment/comment-all-list", {
          Id: this.parameterId,
          BoardType: this.boardType,
          LoginId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          const allComments = Array.isArray(data.data) ? data.data : [];

          this.comments = this.buildCommentTree(allComments);
          console.log("Fetched comments: ", JSON.stringify(this.comments));
        } else {
          console.error("댓글 데이터를 불러오는 중 오류 발생");
        }
      } catch (error) {
        console.error("댓글 불러오기 오류:", error);
      }
    },

    buildCommentTree(comments) {
      const map = {};
      const roots = [];

      comments.forEach((comment) => {
        map[comment.commentId] = { ...comment, replies: [] };
      });

      comments.forEach((comment) => {
        if (comment.parentId) {
          if (map[comment.parentId]) {
            map[comment.parentId].replies.push(map[comment.commentId]);
          }
        } else {
          roots.push(map[comment.commentId]);
        }
      });

      return roots;
    },
    async submitComment() {
      if (this.newCommentContent.trim() !== "") {
        try {
          const response = await apiClient.post(`/api/Comment/comment-add`, {
            Id: this.parameterId,
            BoardType: this.boardType,
            CommentContent: this.newCommentContent,
            UserId: localStorage.getItem("userid"),
            LoginId: localStorage.getItem("userid"),
          });

          if (response.status === 200) {
            this.fetchComments();
            this.newCommentContent = "";
          }
        } catch (error) {
          console.error("댓글 작성 오류:", error);
        }
      }
    },
    addReply(newReply, parentId) {
      const findComment = (commentList, id) => {
        for (const comment of commentList) {
          if (comment.id === id) return comment;
          if (comment.replies.length) {
            const found = findComment(comment.replies, id);
            if (found) return found;
          }
        }
      };

      const parentComment = findComment(this.comments, parentId);
      if (parentComment) {
        parentComment.replies.push(newReply);
      }
    },
  },
};
</script>

<style scoped>
.comment-section {
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.new-comment {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

textarea {
  width: 100%;
  min-height: 100px;
  margin-bottom: 10px;
}

.submit-button {
  display: flex;
  justify-content: flex-end;
}

button {
  padding: 8px 16px;
  background-color: #0692e7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
