<template>
  <div class="wrapper">
    <div class="container">
      <!-- Header에서 발생한 menu-selected 이벤트를 처리 -->
      <MainHeader @menu-selected="onMenuSelected" />

      <div class="body">
        <div class="body_box">
          <!-- Left 메뉴 표시 -->
          <LeftMenu :items="leftMenuItems" />

          <!-- Content 영역 -->
          <router-view />
        </div>
      </div>

      <!-- Footer 영역 -->
      <Footer />
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    MainHeader,
    LeftMenu,
    Footer,
  },
  data() {
    return {
      leftMenuItems: [], // Left 메뉴 항목 저장
    };
  },
  methods: {
    // Header에서 선택된 메뉴에 따라 라우팅 처리
    onMenuSelected(menu, navigate = true) {
      if (menu === "프로젝트관리") {
        if (localStorage.getItem("usertype") === "C") {
          this.leftMenuItems = [
            { name: "프로젝트목록", link: "/projects/companylist" },
          ];
          if (navigate) {
            this.$router.push("/projects/companylist");
          }
        } else {
          this.leftMenuItems = [
            { name: "프로젝트목록 (외주)", link: "/projects/list" },
            { name: "프로젝트목록 (마이즈)", link: "/projects/mizelist" },
          ];
          if (navigate) {
            this.$router.push("/projects/list");
          }
        }
      } else if (menu === "수주관리") {
        this.leftMenuItems = [
          { name: "수주내역", link: "/orders/list" },
          // { name: "나의 수주내역", link: "/orders/self" },
        ];
        if (navigate) {
          this.$router.push("/orders/list");
        }
      } else if (menu === "정산관리") {
        this.leftMenuItems = [{ name: "정산현황", link: "/accounting/list" }];
        if (navigate) {
          this.$router.push("/accounting/list");
        }
      } else if (menu === "담당자관리") {
        this.leftMenuItems = [{ name: "담당자목록", link: "/managers/list" }];
        if (navigate) {
          this.$router.push("/managers/list");
        }
      } else if (menu === "공지사항") {
        this.leftMenuItems = [{ name: "공지사항목록", link: "/notices/list" }];
        if (navigate) {
          this.$router.push("/notices/list");
        }
      } else if (menu === "내정보") {
        this.leftMenuItems = [{ name: "내 정보 수정", link: "/myinfo/view" }];
        if (navigate) {
          this.$router.push("/myinfo/view");
        }
      }
    },
  },
  mounted() {
    const currentPath = this.$route.path;

    if (currentPath.startsWith("/projects")) {
      // 현재 경로가 프로젝트 관련 경로일 때 프로젝트관리 메뉴 선택
      this.onMenuSelected("프로젝트관리", false);
    } else if (currentPath.startsWith("/orders")) {
      this.onMenuSelected("수주관리", false);
    } else if (currentPath.startsWith("/accounting")) {
      this.onMenuSelected("정산관리", false);
    } else if (currentPath.startsWith("/managers")) {
      this.onMenuSelected("담당자관리", false);
    } else if (currentPath.startsWith("/notices")) {
      this.onMenuSelected("공지사항", false);
    } else if (currentPath.startsWith("/myinfo")) {
      this.onMenuSelected("내정보", false);
    }
  },
};
</script>

<style scoped></style>
