<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">프로젝트 상세보기 (업체기준)</div>
        <div class="description">
          프로젝트건을 조회할 수 있습니다. <span class="text_red"></span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">프로젝트상태</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>상태</th>
                  <td>
                    <div :class="getStatusClass(this.orderStatus)">
                      <span>{{ getStatusText(this.orderStatus) }}</span>
                    </div>
                  </td>
                </tr>
                <tr v-if="orderStatus === 'F'">
                  <th>철회 일시</th>
                  <td>{{ withDrawalDtm }}</td>
                </tr>
                <tr v-if="orderStatus === 'F'">
                  <th>철회 사유</th>
                  <td>{{ withDrawalMemo }}</td>
                </tr>
                <tr v-if="orderStatus === 'C'">
                  <th>취소 일시</th>
                  <td>{{ cancelDtm }}</td>
                </tr>
                <tr v-if="orderStatus === 'C'">
                  <th>취소 사유</th>
                  <td>{{ cancelMemo }}</td>
                </tr>
              </tbody>
            </table>
            <div class="part_title">프로젝트정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트명</th>
                  <td>
                    <div class="w_250">{{ projectName }}</div>
                  </td>
                </tr>
                <tr>
                  <th>프로젝트금액<br class="only_mobile" />(vat별도)</th>
                  <td>
                    <div class="w_250">{{ formattedRealAmount }}</div>
                  </td>
                </tr>
                <tr v-if="formattedExtraAmount">
                  <th>추가금액<br class="only_mobile" />(vat별도)</th>
                  <td>
                    <div class="inner_td">
                      <div class="w_250">{{ formattedExtraAmount }}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>지역</th>
                  <td>
                    <div class="w_250">
                      <!-- 지역 ID와 일치하는 지역 이름을 찾아서 표시 -->
                      {{
                        areas.find((area) => area.id === selectedArea)
                          ?.areaName || "지역 정보 없음"
                      }}
                    </div>
                  </td>
                </tr>
                <tr v-if="orderStatus !== 'C'">
                  <th>
                    프로젝트시작일<br class="only_mobile" />선택하기<span
                      class="text_red"
                      >*</span
                    >
                  </th>
                  <td>
                    <div class="inner_td">
                      <!-- 선택된 프로젝트 시작</div>일이 이미 존재하거나, status가 'Y'일 경우 -->
                      <div v-if="choiceSelDate || orderStatus === 'Y'">
                        <span
                          >{{ choiceSelDate }} ({{
                            getDayOfWeek(choiceSelDate)
                          }})</span
                        >
                      </div>
                      <div v-else>
                        <div
                          v-for="(date, index) in selectedStartDates"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :id="'startdate-' + index"
                            v-model="selectedStartDate"
                            :value="date"
                          />
                          <label :for="'startdate-' + index"
                            >{{ " " + date }} ({{ getDayOfWeek(date) }})</label
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>프로젝트<br class="only_mobile" />상세내용</th>
                  <td>
                    <div class="inner_td">
                      <div class="w_250">
                        <span v-if="detailDesc">{{ detailDesc }}</span>
                        <span v-else>미작성</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>투입자재목록</th>
                  <td>
                    <!-- 선택된 자재 목록 -->
                    <div
                      v-for="(material, index) in selectedMaterials"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{
                        material.materialsName +
                        (material.modelName
                          ? " (" + material.modelName + ")"
                          : "")
                      }}</span>
                      <span
                        ><b>{{ material.quantity }} 개</b></span
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <div class="w_250">
                        <span v-if="addMemo">{{ addMemo }}</span>
                        <span v-else>미작성</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>마이즈 담당자 <br class="only_mobile" />(영업)</th>
                  <td>
                    <div class="inner_td">
                      <div v-if="selectedSalesManager">
                        <!-- 전화번호가 존재할 경우 링크로 묶어 클릭 시 전화 걸기 -->
                        <a
                          v-if="selectedSalesManagerPhone"
                          :href="'tel:' + selectedSalesManagerPhone"
                        >
                          {{
                            salesManagers.find(
                              (manager) =>
                                manager.userId === selectedSalesManager
                            )?.adminName || "담당자 선택 안됨"
                          }}
                          <!-- 번호 출력 -->
                          {{ "( " + selectedSalesManagerPhone + " )" }}
                        </a>
                        <!-- 전화번호가 없을 경우 담당자 이름만 출력 -->
                        <span v-else>
                          {{
                            salesManagers.find(
                              (manager) =>
                                manager.userId === selectedSalesManager
                            )?.adminName || "담당자 선택 안됨"
                          }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>마이즈 담당자 <br class="only_mobile" />(PM)</th>
                  <td>
                    <div class="inner_td">
                      <div v-if="selectedPmManager">
                        <!-- 전화번호가 존재할 경우 링크로 묶어 클릭 시 전화 걸기 -->
                        <a
                          v-if="selectedPmManagerPhone"
                          :href="'tel:' + selectedPmManagerPhone"
                        >
                          {{
                            pmManagers.find(
                              (manager) => manager.userId === selectedPmManager
                            )?.adminName || "담당자 선택 안됨"
                          }}
                          <!-- 번호 출력 -->
                          {{ "( " + selectedPmManagerPhone + " )" }}
                        </a>
                        <!-- 전화번호가 없을 경우 담당자 이름만 출력 -->
                        <span v-else>
                          {{
                            pmManagers.find(
                              (manager) => manager.userId === selectedPmManager
                            )?.adminName || "담당자 선택 안됨"
                          }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="
              (orderStatus === 'Y' ||
                orderStatus === 'YY' ||
                orderStatus === 'B') &&
              choiceCompanyId === companyid
            "
            class="tbl_search tbl_info"
          >
            <div class="part_title">고객사 정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>고객사 상호</th>
                  <td>
                    <div class="w_250">{{ customerName }}</div>
                  </td>
                </tr>
                <tr>
                  <th>상세주소</th>
                  <td class="td_address">
                    <div>
                      <div class="w_150">{{ postalCode }}</div>
                    </div>
                    <div class="w_250">{{ address }}</div>
                    <div class="w_250">{{ detailedAddress }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="orderStatus == 'N' || orderStatus == 'F'"
            class="tbl_search tbl_info"
          >
            <div class="part_title">고객사 정보 (수주 업체만 확인 가능)</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>고객사 상호</th>
                  <td>
                    <div class="w_250">수주 후 확인 가능</div>
                  </td>
                </tr>
                <tr>
                  <th>상세주소</th>
                  <td class="td_address">
                    <div>
                      <div class="w_150">수주 후 확인 가능</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">프로젝트 담당자 정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <tr
                  v-if="
                    orderStatus === 'Y' ||
                    orderStatus === 'YY' ||
                    orderStatus === 'B' ||
                    orderStatus === 'C'
                  "
                >
                  <th>수주 업체명</th>
                  <td>
                    <span>{{ choiceCompanyName || "데이터 없음" }}</span>
                  </td>
                </tr>
                <tr
                  v-if="
                    orderStatus === 'Y' ||
                    orderStatus === 'YY' ||
                    orderStatus === 'B' ||
                    orderStatus === 'C'
                  "
                >
                  <th>수주 담당자</th>
                  <td>
                    <!-- status가 'N'이 아니고 choiceUserId !== userId 일 경우 마스킹 처리 -->
                    <div v-if="choiceCompanyId === companyid">
                      <span>{{ choiceUserName }}</span>
                    </div>
                    <!-- status가 'N'이거나 choiceUserId === userId 일 경우 원래 값 표시 -->
                    <div v-else>
                      <span>
                        {{
                          choiceUserName[0]
                            ? choiceUserName[0] + "**"
                            : "데이터 없음"
                        }}</span
                      >
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="
                    orderStatus === 'Y' ||
                    orderStatus === 'YY' ||
                    orderStatus === 'B' ||
                    orderStatus === 'C'
                  "
                >
                  <th>수주 일시</th>
                  <td>
                    <span>{{ choiceDtm || "데이터 없음" }}</span>
                  </td>
                </tr>
                <!-- 총 투입 예정 인원수 -->
                <tr>
                  <th>
                    총 투입 <br class="only_mobile" />예정 인원수<span
                      class="text_red"
                      >*</span
                    >
                  </th>
                  <td>
                    <!-- status가 'Y'일 경우 텍스트로 표시 -->
                    <div
                      v-if="
                        orderStatus === 'Y' ||
                        orderStatus === 'YY' ||
                        orderStatus === 'B' ||
                        orderStatus === 'F' ||
                        orderStatus === 'C'
                      "
                    >
                      <span>{{
                        totalPersonnel ? totalPersonnel + " 명" : "데이터 없음"
                      }}</span>
                    </div>
                    <!-- status가 'N'일 경우 input box로 표시 -->
                    <div v-else>
                      <input
                        type="number"
                        class="w_250"
                        v-model="totalPersonnel"
                        ref="totalPersonnel"
                        placeholder="총 투입 예정 인원수를 입력해주세요"
                      />
                    </div>
                  </td>
                </tr>
                <!-- 투입 인원 프로젝트 담당자 이름 -->
                <tr>
                  <th>
                    프로젝트 <br class="only_mobile" />담당자<span
                      class="text_red"
                      >*</span
                    >
                  </th>
                  <td>
                    <!-- status가 'Y'일 경우 텍스트로 표시 -->
                    <div
                      v-if="
                        orderStatus === 'Y' ||
                        orderStatus === 'YY' ||
                        orderStatus === 'B' ||
                        orderStatus === 'F' ||
                        orderStatus === 'C'
                      "
                    >
                      <!-- status가 'N'이 아니고 choiceUserId !== userId 일 경우 마스킹 처리 -->
                      <div
                        v-if="
                          orderStatus !== 'N' && choiceCompanyId !== companyid
                        "
                      >
                        <span>{{
                          representativeName[0]
                            ? representativeName[0] + "**"
                            : "데이터 없음"
                        }}</span>
                        <!-- 성 + '**' 형태로 마스킹 -->
                      </div>
                      <!-- status가 'N'이거나 choiceUserId === userId 일 경우 원래 값 표시 -->
                      <div v-else>
                        <span>{{ representativeName }}</span>
                      </div>
                    </div>
                    <!-- status가 'N'일 경우 input box로 표시 -->
                    <div v-else>
                      <input
                        type="text"
                        class="w_250"
                        v-model="representativeName"
                        ref="representativeName"
                        placeholder="프로젝트 담당자를 입력해주세요"
                      />
                    </div>
                  </td>
                </tr>
                <!-- 연락처 -->
                <tr>
                  <th>
                    프로젝트 <br class="only_mobile" />담당자 연락처<span
                      class="text_red"
                      >*</span
                    >
                  </th>
                  <td>
                    <!-- status가 'Y'일 경우 텍스트로 표시 -->
                    <div
                      v-if="
                        orderStatus === 'Y' ||
                        orderStatus === 'YY' ||
                        orderStatus === 'B' ||
                        orderStatus === 'F' ||
                        orderStatus === 'C'
                      "
                    >
                      <div
                        v-if="
                          orderStatus !== 'N' && choiceCompanyId !== companyid
                        "
                      >
                        <span>{{
                          representativeContact
                            ? maskPhoneNumber(representativeContact)
                            : "데이터 없음"
                        }}</span>
                      </div>
                      <!-- status가 'N'이거나 choiceUserId === userId 일 경우 원래 값 표시 -->
                      <div v-else>
                        <span>{{ representativeContact }}</span>
                      </div>
                    </div>
                    <!-- status가 'N'일 경우 input box로 표시 -->
                    <div v-else>
                      <input
                        type="text"
                        class="w_250"
                        v-model="representativeContact"
                        ref="representativeContact"
                        @input="formatPhoneNumber"
                        placeholder="연락처를 입력해주세요"
                      />
                    </div>
                  </td>
                </tr>
                <!-- 세금계산서 발급일 -->
                <tr
                  v-if="orderStatus === 'YY' && choiceCompanyId === companyid"
                >
                  <th>세금계산서 발급일<span class="text_red">*</span></th>
                  <td>
                    <!-- selectedBillDate 값이 있으면 문구로 표시 -->
                    <!-- <div v-if="selectedBillDate">
                        <span>{{ selectedBillDate }}</span>
                      </div> -->
                    <!--  v-else 값이 없으면 입력 필드로 표시 -->
                    <div>
                      <input
                        type="date"
                        class="w_250"
                        v-model="selectedBillDate"
                      />
                    </div>
                  </td>
                </tr>
                <!-- 세금계산서 발급일 -->
                <tr v-if="orderStatus === 'B' && choiceCompanyId === companyid">
                  <th>세금계산서 발급일<span class="text_red">*</span></th>
                  <td>
                    <!-- status가 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{ selectedBillDate }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- 동의하기 체크박스 -->
          <div
            class="checkbox-container"
            v-if="
              orderStatus === 'N' ||
              ((orderStatus === 'Y' ||
                orderStatus === 'YY' ||
                orderStatus === 'B') &&
                choiceUserId === userId)
            "
          >
            <input
              type="checkbox"
              id="agreementCheckbox"
              v-model="isAgreed"
              :disabled="
                orderStatus === 'Y' ||
                orderStatus === 'YY' ||
                orderStatus === 'B'
              "
            />
            <label for="agreementCheckbox">
              본인은 위의 프로젝트 일정 및 내용을 충분히 숙지하였으며, 미이행 시
              발생하는 모든 불이익에 대해 동의합니다.
            </label>
          </div>

          <div class="btn_bottom_a">
            <button
              v-if="orderStatus === 'N'"
              type="button"
              class="on"
              @click="saveConstruction"
            >
              수주하기
            </button>
            <button
              v-if="orderStatus === 'YY' && choiceCompanyId === companyid"
              type="button"
              class="on"
              @click="saveBillConstruction"
            >
              저장
            </button>
            <button type="button" @click="goToConstructionList">목록</button>
          </div>
        </article>
      </div>
      <!-- 삼성인터넷브라우저에서 안되서 원인 파악하다가 스타일문제로 확인! 임시방편임 -->
      <div style="margin-top: 100px"></div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "OrdersView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isAgreed: false, // 동의 체크박스 상태를 저장하는 변수
      selectedArea: "0", // 전체보기 기본 값 설정
      areas: [], // API에서 가져온 지역 목록을 저장할 배열
      materials: [], // 자재 목록을 저장할 배열
      selectedMaterial: "", // 선택된 자재를 저장할 변수
      selectedMaterials: [], // 선택된 자재 목록을 저장할 배열
      selectedStartDate: "", // 선택된 프로젝트 시작일
      selectedStartDates: [], // 선택된 프로젝트 시작일 목록
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      salesManagers: [], // 영업 담당자 목록을 저장할 배열
      selectedSalesManager: "", // 선택된 영업 담당자
      selectedSalesManagerPhone: "", // 선택된 영업 담당자 핸드폰번호
      pmManagers: [], // PM 담당자 목록을 저장할 배열
      selectedPmManager: "", // 선택된 PM 담당자
      selectedPmManagerPhone: "", // 선택된 PM 담당자 핸드폰번호
      extraAmount: 0, // 실제 금액을 저장하는 변수
      formattedExtraAmount: "", // ','가 포함된 금액을 저장하는 변수
      formattedRealAmount: "", // ','가 포함된 금액을 저장하는 변수
      orderStatus: "",
      choiceSelDate: "", // 선택된 프로젝트 시작일 값 추가
      selectedBillDate: "",
      selectedCheckboxDates: [], // 체크박스에서 선택된 프로젝트 시작일 목록
      daysOfWeek: ["일", "월", "화", "수", "목", "금", "토"], // 요일 배열
      totalPersonnel: "", // 총 투입 예정 인원수를 저장하는 변수
      representativeName: "", // 프로젝트 담당자 이름을 저장하는 변수
      representativeContact: "", // 프로젝트 담당자 연락처를 저장하는 변수
      choiceDtm: "",
      choiceCompanyId: "",
      choiceCompanyName: "",
      choiceUserId: "",
      choiceUserName: "",

      withDrawalDtm: "",
      withDrawalMemo: "",
      cancelDtm: "",
      cancelMemo: "",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    companyid() {
      return this.$store.getters.getcompanyid;
    },
  },
  methods: {
    // 연락처를 마스킹 처리하는 함수
    maskPhoneNumber(phone) {
      if (!phone) return "";
      // 010-****-**** 형태로 마스킹
      return phone.replace(/(\d{3})-\d{4}-\d{4}/, "$1-****-****");
    },
    // <!-- 프로젝트건 상태 (수주전-'N'/수주완료-'Y'/프로젝트완료-'YY'/계산서발급완료-'B'/수주철회-'F'/프로젝트취소-'C') -->
    // 상태에 따른 문구 반환
    getStatusText(orderStatus) {
      switch (orderStatus) {
        case "N":
          return "수주전";
        case "Y":
          return "수주완료";
        case "YY":
          return "프로젝트완료";
        case "B":
          return "계산서발급완료";
        case "F":
          return "수주철회";
        case "C":
          return "프로젝트취소";
        case "E":
          return "정산완료";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(orderStatus) {
      switch (orderStatus) {
        case "N":
          return "stat back_green"; // 수주전
        case "Y":
          return "stat back_blue"; // 수주완료
        case "YY":
          return "stat back_grey"; // 프로젝트완료
        case "B":
          return "stat back_red"; // 계산서발급완료
        case "F":
          return "stat back_orange"; // 수주철회
        case "C":
          return "stat back_lightgrey"; // 프로젝트취소
        case "E":
          return "stat back_purple"; // 정산완료
        default:
          return "stat back_default"; // 기본 값
      }
    },
    // 전화번호 포맷팅 함수
    formatPhoneNumber(event) {
      let input = event.target.value.replace(/\D/g, ""); // 숫자 이외의 문자 제거

      // 변환된 값을 담을 변수 선언
      let formattedNumber = "";

      if (input.length <= 3) {
        formattedNumber = input;
      } else if (input.length <= 7) {
        formattedNumber = input.slice(0, 3) + "-" + input.slice(3);
      } else {
        formattedNumber =
          input.slice(0, 3) +
          "-" +
          input.slice(3, 7) +
          "-" +
          input.slice(7, 11);
      }

      // 포맷팅된 값을 다시 반영
      this.representativeContact = formattedNumber;
    },
    // 날짜 문자열을 받아 해당 요일을 반환
    getDayOfWeek(date) {
      const day = new Date(date).getDay();
      return this.daysOfWeek[day];
    },
    selectPmManager(pmmanagerId) {
      this.selectedPmManager = pmmanagerId;
    },
    async fetchPmManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/pm-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.pmManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.pmManagers);
        }
      } catch (error) {
        console.error("Error fetching pm managers:", error);
      }
    },
    selectSalesManager(salesmanagerId) {
      this.selectedSalesManager = salesmanagerId;
    },
    async fetchSalesManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/sales-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.salesManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.salesManagers);
        }
      } catch (error) {
        console.error("Error fetching sales managers:", error);
      }
    },
    async fetchMaterials() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Materials/all-materials-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.materials = Array.isArray(data.data) ? data.data : [];
          console.log(this.materials);
        }
      } catch (error) {
        console.error("Error fetching materials:", error);
      }
    },
    goToConstructionList() {
      this.$router.push({ path: "/orders/list" });
    },

    async fetchAreas() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Area/all-area-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.areas = Array.isArray(data.data) ? data.data : [];
          console.log(this.areas);
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    },
    async saveBillConstruction() {
      // 세금계산서 발급일이 선택되었는지 확인
      if (!this.selectedBillDate) {
        this.toast.error("세금계산서 발급일 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      try {
        // 데이터 저장을 위한 API 호출 로직
        const response = await apiClient.post(
          "/api/Construction/construction-billdate-update",
          {
            Id: this.id,
            BillDate: this.selectedBillDate,
            LoginId: localStorage.getItem("userid"),
            // 필요한 다른 필드 추가
          }
        );

        if (response.status === 200) {
          this.toast.success("세금계산서 발급일이 성공적으로 저장되었습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          this.goToConstructionList(); // 목록으로 이동
          //this.$router.go(0); // 현재 페이지를 새로고침
        }
      } catch (error) {
        console.error("Error saving construction data:", error);
        this.toast.error("세금계산서 발급일이 저장 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    // 수주하기 저장
    async saveConstruction() {
      if (this.checkFields()) {
        // 프로젝트를 수주하시겠습니까? 확인 메시지
        const confirmation = confirm("해당 프로젝트를 수주하시겠습니까?");
        if (confirmation) {
          // 확인을 누르면 데이터 저장 로직 실행
          try {
            // 데이터 저장을 위한 API 호출 로직
            const response = await apiClient.post(
              "/api/Construction/construction-accept",
              {
                Id: this.id,
                ChoiceSelDate: this.selectedStartDate,
                TotalPeople: this.totalPersonnel,
                ManagerUserName: this.representativeName,
                ManagerUserPhone: this.representativeContact,
                LoginId: localStorage.getItem("userid"),
                // 필요한 다른 필드 추가
              }
            );

            if (response.status === 200) {
              this.toast.success(
                "프로젝트 수주 처리가 성공적으로 수정되었습니다.",
                {
                  position: "top-center", // 메시지 위치
                  timeout: 1500, // 3초 동안 표시
                  closeOnClick: true,
                  pauseOnHover: true,
                  hideProgressBar: true, // 진행 표시줄 숨기기
                  closeButton: false, // X 버튼 숨기기
                }
              );
              //this.goToConstructionList(); // 목록으로 이동
              this.$router.go(0); // 현재 페이지를 새로고침
            }
          } catch (error) {
            console.error("Error saving construction data:", error);
            this.toast.error("프로젝트 수주 처리 중 오류가 발생했습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }
        }
      }
    },
    async fetchConstructionData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post(
          "/api/Construction/construction-info",
          {
            Id: this.id,
            LoginId: localStorage.getItem("userid"),
          }
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.constructionInfo = Array.isArray(data.data) ? data.data : [];
          console.log(
            "☆☆☆☆☆☆☆☆this.constructionInfo[0]☆☆☆☆☆☆☆☆☆" +
              this.constructionInfo[0]
          );

          this.projectName = this.constructionInfo[0].projectName;
          this.formattedRealAmount = this.constructionInfo[0].price;
          this.formattedExtraAmount =
            this.constructionInfo[0].addPrice == "0"
              ? ""
              : this.constructionInfo[0].addPrice;
          this.selectedArea = this.constructionInfo[0].areaId;
          // 시작일 부분 split 해서 ui 표현해야 함
          this.formattedStartDates = this.constructionInfo[0].startDate;
          this.selectedStartDates = this.formattedStartDates.split("^"); // 날짜를 배열로 변환

          this.postalCode = this.constructionInfo[0].zipCode;
          this.address = this.constructionInfo[0].addr1;
          this.detailedAddress = this.constructionInfo[0].addr2;
          this.detailDesc = this.constructionInfo[0].detailContent;
          this.orderStatus = this.constructionInfo[0].orderStatus;
          //this.selectedMaterials = this.constructionInfo[0].
          this.choiceSelDate = data.data[0].choiceSelDate; // 프로젝트 시작일 선택 값 설정
          this.addMemo = this.constructionInfo[0].etcMemo;
          this.selectedSalesManager = this.constructionInfo[0].mizeSalesUserId;
          this.selectedPmManager = this.constructionInfo[0].mizePMUserId;

          this.selectedSalesManagerPhone =
            this.constructionInfo[0].mizeSalesUserPhone;
          this.selectedPmManagerPhone =
            this.constructionInfo[0].mizePMUserPhone;

          this.customerName = this.constructionInfo[0].customerName;
          this.totalPersonnel = this.constructionInfo[0].totalPeople;
          this.representativeName = this.constructionInfo[0].managerUserName;
          this.representativeContact =
            this.constructionInfo[0].managerUserPhone;
          this.selectedBillDate = this.constructionInfo[0].billDate;

          this.choiceDtm = this.constructionInfo[0].choiceDtm;
          this.choiceCompanyId = this.constructionInfo[0].choiceCompanyId;
          this.choiceCompanyName = this.constructionInfo[0].choiceCompanyName;
          this.choiceUserId = this.constructionInfo[0].choiceUserId;
          this.choiceUserName = this.constructionInfo[0].choiceUserName;

          this.withDrawalDtm = this.constructionInfo[0].withDrawalDtm;
          this.withDrawalMemo = this.constructionInfo[0].withDrawalMemo;

          this.cancelDtm = this.constructionInfo[0].canceledDtm;
          this.cancelMemo = this.constructionInfo[0].cancelMemo;

          if (this.orderStatus === "N") this.isAgreed = false;
          else if (this.orderStatus === "Y") this.isAgreed = true;
          else if (this.orderStatus === "YY") this.isAgreed = true;
          else if (this.orderStatus === "B") this.isAgreed = true;

          // 프로젝트 정보에 자재 목록이 포함되어 있는 경우 처리
          if (this.constructionInfo[0].materials) {
            this.selectedMaterials = this.constructionInfo[0].materials.map(
              (material) => ({
                id: material.materialId,
                materialsName: material.materialName,
                modelName: material.modelName || "", // 모델명이 있을 경우 포함
                quantity: material.materialCnt || 1, // 수량 정보가 없으면 기본 수량 1
              })
            );
          }
          // console.log(this.meetingroomInfo[0].timeSettings);
        }
      } catch (error) {
        console.error("Error fetching meetingroomInfo data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    checkFields() {
      // 프로젝트 시작일이 선택되지 않았는지 확인
      if (!this.selectedStartDate) {
        this.toast.error("프로젝트 시작일을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      // 입력 값이 올바른지 확인
      if (!this.totalPersonnel || this.totalPersonnel <= 0) {
        this.$refs.totalPersonnel.focus(); // 포커스 이동
        this.toast.error("총 투입 예정 인원수를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (!this.representativeName) {
        this.$refs.representativeName.focus(); // 포커스 이동
        this.toast.error("프로젝트 담당자를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (!this.representativeContact) {
        this.$refs.representativeContact.focus(); // 포커스 이동
        this.toast.error("프로젝트 담당자 연락처를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      // 연락처 유효성 검사 (10자리 또는 11자리의 숫자로 구성)
      const cleanedContact = this.representativeContact.replace(/\D/g, ""); // '-' 제거 후 숫자만 남김
      if (cleanedContact.length !== 10 && cleanedContact.length !== 11) {
        this.$refs.representativeContact.focus(); // 포커스 이동
        this.toast.error(
          "유효한 연락처를 입력해주세요. (10자리 또는 11자리).",
          {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          }
        );
        return false;
      }
      // 동의 체크박스가 체크되지 않았을 경우 알림
      if (!this.isAgreed) {
        this.toast.error("본인 동의에 체크 해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      return true;
    },
  },
  mounted() {
    this.fetchAreas(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchMaterials(); // 자재 데이터를 가져오기
    this.fetchSalesManagers(); // 영업 담당자 목록을 가져옴
    this.fetchPmManagers(); // 영업 담당자 목록을 가져옴
    this.fetchConstructionData(); // 프로젝트 상세정보
  },
};
</script>

<style scoped></style>
