<template>
  <div>
    <div v-if="isModalVisible3" class="modal">
      <div class="modal-content">
        <p>
          "{{ showModalForFields[currentFieldIndex] }}" 항목이 변경되었습니다.
        </p>
        <h3>변경된 이유를 입력해주세요</h3>
        <textarea v-model="Reason" class="textarear"></textarea>
        <div class="dpop_bottom_btns">
          <button @click="submitReason">제출</button>
          <button @click="closeModal">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "ReasonModal", // 여기서 컴포넌트 이름을 ReasonModal로 수정
  setup() {
    const toast = useToast();
    return { toast };
  },
  props: {
    isModalVisible3: {
      type: Boolean,
      required: true,
    },
    showModalForFields: {
      type: Array,
      required: true,
    },
    currentFieldIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      Reason: "", // 현재 입력된 변경 이유를 저장하는 변수
      localIsModalVisible3: this.isModalVisible3,
    };
  },
  watch: {
    isModalVisible3(newVal) {
      this.localIsModalVisible3 = newVal; // isModalVisible3 값이 변경되면 local 값도 업데이트
    },
  },
  methods: {
    submitReason() {
      // 이유가 입력되지 않았을 경우에 대한 처리
      if (!this.Reason) {
        this.toast.error("변경 이유를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }

      // 변경된 이유를 부모 컴포넌트로 전달
      this.$emit("submit-reason", this.Reason);

      // 제출 후 입력란을 초기화
      this.Reason = "";
    },
    closeModal() {
      this.$emit("close-modal"); // 부모 컴포넌트에 이벤트를 발생시켜 모달을 닫음
      this.Reason = ""; // 입력란 초기화
      this.localIsModalVisible3 = false; // 로컬 상태 업데이트
    },
  },
};
</script>

<style>
.modal {
  /* 모달창 스타일 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  text-align: center;
}
</style>
