<template>
  <header class="header">
    <div class="header_top">
      <div class="header_topbox">
        <div class="header_top_column">
          <a href="https://mizeinc.co.kr" target="_blank">MIZE INC.</a>
        </div>
        <div class="header_top_column">
          <!-- Vue 데이터 바인딩해야 함 -->
          <span class="top_name">{{ username }} 님 </span>
          <router-link
            v-if="userType !== 'M'"
            to="/myinfo/view"
            @click="selectMenu('내정보')"
            >내 정보</router-link
          >
          <a href="#" @click.prevent="logout">로그아웃</a>
        </div>
      </div>
    </div>
    <nav class="header_bottom">
      <div class="header_bottombox">
        <!--이거 할때랑 스타일이 달라짐 체크해야 함-->
        <div class="logo">
          <img src="@/images/logo_w.png" @click="handleLogoClick" />
        </div>
        <div class="menu">
          <router-link
            v-if="userType === 'C'"
            to="/projects/companylist"
            :class="{ on: isActive('/projects') }"
            @click="refreshManagers(2)"
          >
            <img src="@/images/topic_thum_69.png" alt="" />프로젝트관리
          </router-link>
          <router-link
            v-if="userType === 'M'"
            to="/projects/list"
            :class="{ on: isActive('/projects') }"
            @click="refreshManagers(1)"
          >
            <img src="@/images/topic_thum_69.png" alt="" />프로젝트관리
          </router-link>
          <router-link
            v-if="userType === 'C'"
            to="/orders/list"
            :class="{ on: isActive('/orders') }"
            @click="refreshManagers(3)"
          >
            <img src="@/images/topic_thum_65.png" alt="" />수주관리
          </router-link>
          <router-link
            to="/accounting/list"
            :class="{ on: isActive('/accounting') }"
            @click="refreshManagers(4)"
          >
            <img src="@/images/topic_thum_55.png" alt="" />정산관리
          </router-link>
          <router-link
            v-if="userType === 'M'"
            to="/managers/list"
            :class="{ on: isActive('/managers') }"
            @click="refreshManagers(5)"
          >
            <img src="@/images/topic_thum_57.png" alt="" />담당자관리
          </router-link>
          <router-link
            v-if="userType === 'C'"
            to="/notices/list"
            :class="{ on: isActive('/notices') }"
            @click="refreshManagers(6)"
          >
            <img src="@/images/topic_thum_57.png" alt="" />공지사항
          </router-link>
          <router-link
            v-if="userType === 'M'"
            to="/notices/list"
            :class="{ on: isActive('/notices') }"
            @click="refreshManagers(7)"
          >
            <img src="@/images/topic_thum_57.png" alt="" />공지사항관리
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "MainHeader", // 다중 단어로 변경
  setup() {
    const toast = useToast();
    return { toast };
  },
  emits: ["menu-selected"], // 이벤트 정의 추가
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    selectedMenu() {
      return this.$store.getters.getSelectedMenu; // Vuex 상태에서 현재 선택된 메뉴를 가져옴
    },
  },
  async mounted() {
    this.checkAuth();

    const token_userid = localStorage.getItem("userid");

    if (token_userid) {
      const user = {
        UserId: token_userid,
      };

      // API 호출
      if (user.UserId) {
        try {
          const response = await apiClient.post("/api/User/user-my-info", user);

          if (response.status === 200) {
            const userName = response.data.user.AdminName;
            const usertype = response.data.user.UserType;

            this.$store.dispatch("updateUserName", userName); // Vuex 스토어와 localStorage에 저장
            this.$store.dispatch("updateUserType", usertype); // Vuex 스토어와 localStorage에 저장
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    } else {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    handleLogoClick() {
      const randomString = new Date().getTime();

      this.$router.replace({
        path: "/notices/list",
        query: { reload: randomString },
      });
      // if (this.userType === "C") {
      //   this.$router.push("/projects/companylist"); // 'C' 사용자일 경우 회사 프로젝트 리스트로 이동

      //   const randomString = new Date().getTime();

      //   this.$router.replace({
      //     path: "/projects/companylist",
      //     query: { reload: randomString },
      //   });
      // } else if (this.userType === "M") {
      //   this.$router.push("/projects/list"); // 'M' 사용자일 경우 개인 프로젝트 리스트로 이동

      //   const randomString = new Date().getTime();

      //   this.$router.replace({
      //     path: "/projects/list",
      //     query: { reload: randomString },
      //   });
      // }
    },
    refreshManagers(num) {
      if (this.$route.path === "/notices/list" && num === 7) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString = new Date().getTime();

        this.$router.replace({
          path: "/notices/list",
          query: { reload: randomString },
        });
      }
      if (this.$route.path === "/notices/list" && num === 6) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString = new Date().getTime();

        this.$router.replace({
          path: "/notices/list",
          query: { reload: randomString },
        });
      }
      if (this.$route.path === "/managers/list" && num === 5) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString = new Date().getTime();

        this.$router.replace({
          path: "/managers/list",
          query: { reload: randomString },
        });
      }
      if (this.$route.path === "/projects/list" && num === 1) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString2 = new Date().getTime();

        this.$router.replace({
          path: "/projects/list",
          query: { reload: randomString2 },
        });
      }
      if (this.$route.path === "/accounting/list" && num === 4) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString3 = new Date().getTime();

        this.$router.replace({
          path: "/accounting/list",
          query: { reload: randomString3 },
        });
      }
      if (this.$route.path === "/orders/list" && num === 3) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString4 = new Date().getTime();

        this.$router.replace({
          path: "/orders/list",
          query: { reload: randomString4 },
        });
      }
      if (this.$route.path === "/projects/companylist" && num === 2) {
        // 동일한 경로로 클릭했을 때는 그냥 페이지를 새로고침합니다.
        const randomString5 = new Date().getTime();

        this.$router.replace({
          path: "/projects/companylist",
          query: { reload: randomString5 },
        });
      }
    },
    checkAuth() {
      const token_userid = localStorage.getItem("userid");

      if (!token_userid) {
        this.$router.push({ path: "/" });
      } else if (this.$route.path === "/") {
        this.$router.push({ path: "/myinfo/view" });
      }
    },
    logout() {
      if (confirm("로그아웃 하시겠습니까?")) {
        this.toast.success("로그아웃 되었습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        localStorage.clear();
        this.$store.dispatch("updateUserName", ""); // Vuex 스토어에서 사용자 이름 제거
        this.$store.dispatch("updateUserType", ""); // Vuex 스토어에서 사용자 이름 제거
        this.$router.push({ path: "/" });
      }
    },

    selectMenu(menu) {
      // 선택된 메뉴에 따라 이벤트 발생
      if (menu === "정산관리") {
        this.showComingSoonMessage();
      } else {
        // 선택된 메뉴에 따라 이벤트 발생
        this.$emit("menu-selected", menu);
      }
    },
    isActive(path) {
      return this.$route.path.startsWith(path);
    },
  },
};
</script>

<style scoped>
/* 필요한 스타일 정의 */
.logo img {
  cursor: pointer; /* 클릭 가능한 커서 모양 추가 */
  filter: none; /* 불필요한 필터 제거 */
  opacity: 1; /* 투명도 유지 */
}
</style>
