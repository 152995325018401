<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공지사항 수정</div>
        <div class="description">
          공지사항 수정합니다. <span class="text_red">(* 필수항목)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">공지사항 정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>상단고정 유무<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: selectedFixStatus === 'Y' }"
                        @click="selectFixStatus('Y')"
                      >
                        예
                      </button>
                      <button
                        type="button"
                        :class="{ on: selectedFixStatus === 'N' }"
                        @click="selectFixStatus('N')"
                      >
                        아니오
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>공지사항 분류<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="noticecategory in noticeCategorys"
                        :key="noticecategory.categoryId"
                        type="button"
                        :class="{
                          on:
                            selectedNoticeCategory ===
                            noticecategory.categoryId,
                        }"
                        @click="selectNoticeCategory(noticecategory.categoryId)"
                      >
                        {{ noticecategory.categoryName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>공지사항 제목<span class="text_red">*</span></th>
                  <td class="notice_title_admin">
                    <input
                      type="text"
                      placeholder="공지사항 제목을 입력해주세요"
                      v-model="noticeSubject"
                      ref="noticeSubject"
                      class="notice_title_input"
                    />
                    <span style="margin-right: 5px"></span>
                    <input
                      type="color"
                      v-model="titleColor"
                      class="custom-color-input"
                      title="제목 색상 선택"
                    /><br />
                    <span style="margin-left: 5px; color: darkgray"
                      >* 제목 색상 선택(기본: 검정)</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <quill-editor
              v-model="content"
              ref="content"
              :options="editorOptions"
              class="custom-quill-editor"
              @ready="onEditorReady"
            />
          </div>

          <!-- 기존 HTML 아래에 추가 -->
          <div class="tbl_search tbl_info">
            <div class="part_title"></div>
            <div class="filebox">
              첨부파일 ( 100MB 이상의 파일은 업로드 할 수 없습니다.)
              <div class="file-upload-container">
                <input
                  id="file-upload"
                  type="file"
                  @change="handleFileUpload"
                  multiple
                />
                <span v-if="allFiles.length > 0"
                  >총 선택 첨부파일
                  <span class="accent">{{ allFiles.length }}</span
                  >개</span
                >
                <span v-else> 첨부파일 <span class="accent">없음</span></span>
              </div>
              <ul class="files column">
                <!-- 합쳐진 리스트로 파일 리스트를 보여줌 -->
                <li v-for="(file, index) in allFiles" :key="index">
                  <button @click="downloadFile(file)">{{ file }}</button>
                  <button @click="removeFile(index)">삭제</button>
                </li>
              </ul>
            </div>
          </div>

          <div class="btn_bottom_a">
            <button type="button" class="on" @click="checkChanges">수정</button>
            <button type="button" @click="goToNoticeList">취소</button>
          </div>
        </article>
      </div>
      <div style="margin-top: 10px"></div>
      <CommentSection :parameterId="this.id" :boardType="'공지사항'" />
    </div>
  </div>
  <!-- 변경된 이유 입력 모달  -->
  <ReasonModal
    v-if="isModalVisible3"
    @close-modal="closeModal"
    :isModalVisible3="isModalVisible3"
    :showModalForFields="showModalForFields"
    :currentFieldIndex="currentFieldIndex"
    @submit-reason="submitChangeReason"
  />
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";
// Quill 및 이미지 리사이즈 모듈 import
import { quillEditor, Quill } from "vue3-quill";
import ImageResize from "quill-image-resize";
import CommentSection from "@/components/comments/CommentSection.vue";
import ReasonModal from "@/components/comments/ReasonModal.vue"; // 모달 컴포넌트 import
// Quill에 이미지 리사이즈 모듈 등록
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "NoticesView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL, // 환경 변수 가져오기
      noticeId: "",
      selectedFixStatus: "", // 선택된 상단고정 유무
      noticeCategorys: [], // PM 담당자 목록을 저장할 배열
      selectedNoticeCategory: "", // 선택된 공지사항 카테고리 값
      noticeSubject: "", // 공지사항 제목
      titleColor: "#000000", // 제목의 기본 색상 (검정색)
      content: "", // 에디터 내용

      attachedFileList: [], // 첨부파일 리스트를 저장할 배열
      uploadedFiles: [], // 새로운 첨부파일을 저장할 배열
      editorReady: false, // 에디터가 준비되었는지 여부
      editorOptions: {
        modules: {
          // 이미지 리사이즈 모듈 활성화
          imageResize: {},
          toolbar: [
            [{ font: [] }], // 글꼴 선택
            [{ size: [] }], // 글자 크기
            ["bold", "italic", "underline", "strike"], // 굵게, 기울임, 밑줄, 취소선
            [{ color: [] }, { background: [] }], // 글자 색상 및 배경색
            [{ script: "sub" }, { script: "super" }], // 아래첨자, 위첨자
            [{ header: 1 }, { header: 2 }], // 헤더 1, 헤더 2
            ["blockquote", "code-block"], // 인용구, 코드 블록
            [{ list: "ordered" }, { list: "bullet" }], // 순서 있는 목록, 불릿 목록
            [{ indent: "-1" }, { indent: "+1" }], // 들여쓰기, 내어쓰기
            [{ align: [] }], // 텍스트 정렬
            ["link", "image", "video"], // 링크, 이미지, 비디오 삽입
            ["clean"], // 서식 제거
          ],
        },
      }, // quill 에디터 옵션

      // 기존 데이터
      originalData: {}, // fetchConstructionData()로 가져온 데이터를 저장

      // 변경된 항목에 대한 이유
      changeReasons: [], // 변경된 필드별 이유 저장
      showModalForFields: [], // 변경된 필드를 추적하여 모달창을 하나씩 띄우기 위한 배열
      isModalVisible3: false, // 모달창 표시 여부
      currentFieldIndex: 0, // 현재 모달창에서 보여줄 필드 인덱스
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    allFiles() {
      // attachedFileList와 uploadedFiles의 이름을 합쳐서 하나의 리스트로 반환
      return [
        ...this.attachedFileList,
        ...this.uploadedFiles.map((file) => file.name), // 업로드된 파일의 이름만 사용
      ];
    },
  },
  components: {
    quillEditor,
    CommentSection,
    ReasonModal,
  },
  watch: {
    content(newValue) {
      const quillEditor = this.$refs.content.quill;
      if (quillEditor) {
        quillEditor.clipboard.dangerouslyPasteHTML(newValue); // 새 값을 Quill 에디터에 삽입
      }
    },
  },
  methods: {
    closeModal() {
      this.isModalVisible3 = false; // 모달을 닫기 위해 false로 설정
    },
    // 기존 데이터와 입력 데이터를 비교하는 메서드
    checkChanges() {
      this.showModalForFields = [];

      if (this.originalData.fixYN !== this.selectedFixStatus) {
        this.showModalForFields.push("상단고정 유무");
      }

      if (this.originalData.categoryId !== this.selectedNoticeCategory) {
        this.showModalForFields.push("공지사항 분류");
      }

      if (this.originalData.noticeSubject !== this.noticeSubject) {
        this.showModalForFields.push("공지사항 제목");
      }

      if (this.originalData.noticeSubjectColor !== this.titleColor) {
        this.showModalForFields.push("공지사항 제목 색깔");
      }
      const quillEditor = this.$refs.content.$el.querySelector(".ql-editor");

      // HTML 콘텐츠를 다시 가져옴 (이미지 URL로 변경된 상태)
      const updatedContent = quillEditor.innerHTML;

      if (this.originalData.noticeContent !== updatedContent) {
        this.showModalForFields.push("내용");
      }

      // 추가: 첨부파일 비교 로직
      const currentFiles = this.allFiles; // 현재 첨부파일 리스트
      const originalFiles = this.originalData.attachFiles
        ? this.originalData.attachFiles.split(",")
        : [];

      // 첨부파일 리스트가 다르면 수정 항목에 추가
      if (JSON.stringify(originalFiles) !== JSON.stringify(currentFiles)) {
        this.showModalForFields.push("첨부파일");
      }

      if (this.showModalForFields.length > 0) {
        this.isModalVisible3 = true;
        this.currentFieldIndex = 0;
      } else {
        // 변경된 필드가 없으면 바로 저장
        this.saveNotice();
      }
    },
    // 현재 모달창에서 이유를 입력하고 다음 필드로 넘어가는 메서드
    submitChangeReason(reason) {
      const field = this.showModalForFields[this.currentFieldIndex];
      let originval = "";
      let newval = "";

      if (field === "상단고정 유무") {
        originval = this.originalData.fixYN === "Y" ? "예" : "아니오";
        newval = this.selectedFixStatus === "Y" ? "예" : "아니오";
      }

      if (field === "공지사항 분류") {
        // 카테고리 ID 대신 이름으로 변환
        const originalCategory = this.noticeCategorys.find(
          (category) => category.categoryId === this.originalData.categoryId
        );
        const newCategory = this.noticeCategorys.find(
          (category) => category.categoryId === this.selectedNoticeCategory
        );
        originval = originalCategory ? originalCategory.categoryName : "N/A";
        newval = newCategory ? newCategory.categoryName : "N/A";
      }

      if (field === "공지사항 제목") {
        originval = this.originalData.noticeSubject
          ? this.originalData.noticeSubject
          : "";
        newval = this.noticeSubject;
      }
      // if (field === "공지사항 제목 색깔") {
      //   originval = this.originalData.noticeSubjectColor
      //     ? this.originalData.titleColor
      //     : 0;
      //   newval = this.titleColor;
      // }
      if (field === "공지사항 제목 색깔") {
        originval = this.originalData.noticeSubjectColor;
        newval = this.titleColor;

        // 원래 값과 새로운 값을 색상 네모로 표시
        // originval = `<div class="color-box" style="background-color: ${this.originalData.noticeSubjectColor};"></div>`;
        // newval = `<div class="color-box" style="background-color: ${this.titleColor};"></div>`;
      }

      const quillEditor = this.$refs.content.$el.querySelector(".ql-editor");

      // HTML 콘텐츠를 다시 가져옴 (이미지 URL로 변경된 상태)
      const updatedContent = quillEditor.innerHTML;

      if (field === "내용") {
        originval = this.originalData.noticeContent;
        newval = updatedContent;
      }

      // 추가: 첨부파일 비교 처리
      if (field === "첨부파일") {
        const originalFiles = this.originalData.attachFiles
          ? this.originalData.attachFiles.split(",")
          : [];
        originval = originalFiles.join(", ");
        newval = this.allFiles.join(", ");
      }

      // 배열에 { fieldName, reason } 객체를 추가
      this.changeReasons.push({
        ItemName: field,
        OriginValue: originval,
        NewValue: newval,
        Reason: reason,
      });

      // 다음 필드로 이동
      this.currentFieldIndex++;

      // 모든 필드를 처리했을 때
      if (this.currentFieldIndex >= this.showModalForFields.length) {
        this.isModalVisible3 = false;
        this.saveNotice();
      }
    },

    handleFileUpload(event) {
      const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB로 파일 크기 제한
      const MAX_FILE_COUNT = 10; // 최대 10개의 파일 제한
      const files = event.target.files;

      if (
        this.uploadedFiles.length +
          this.attachedFileList.length +
          files.length >
        MAX_FILE_COUNT
      ) {
        this.toast.error("첨부파일은 최대 10개까지 선택 가능합니다.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
        return;
      }

      const duplicateFiles = [];
      const oversizedFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const duplicate =
          this.uploadedFiles.some(
            (uploadedFile) => uploadedFile.name === file.name
          ) ||
          this.attachedFileList.some(
            (attachedFile) => attachedFile === file.name
          );

        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(file.name);
          continue;
        }

        if (duplicate) {
          duplicateFiles.push(file.name);
        } else {
          this.uploadedFiles.push(file); // 새로 업로드된 파일 추가
        }
      }

      if (duplicateFiles.length > 0) {
        this.toast.error(
          `다음 파일은 이미 추가되었습니다.\r\n\r\n${duplicateFiles.join(
            "\r\n"
          )}`,
          {
            position: "top-center",
            timeout: 1500,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true,
            closeButton: false,
          }
        );
      }

      if (oversizedFiles.length > 0) {
        this.toast.error(
          `다음 파일은 크기 제한(100MB)을 초과했습니다.\r\n\r\n${oversizedFiles.join(
            "\r\n"
          )}`,
          {
            position: "top-center",
            timeout: 1500,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true,
            closeButton: false,
          }
        );
      }
    },
    removeFile(index) {
      // allFiles 배열에서 파일을 삭제할 때, 업로드된 파일 또는 기존 파일인지 구분하여 삭제
      if (index < this.attachedFileList.length) {
        this.attachedFileList.splice(index, 1); // 기존 첨부파일 리스트에서 삭제
      } else {
        this.uploadedFiles.splice(index - this.attachedFileList.length, 1); // 새로 업로드된 파일 리스트에서 삭제
      }
    },
    async downloadFile(fileName) {
      try {
        const response = await apiClient.get(
          `/api/Notice/download-file?fileName=${fileName}`,
          { responseType: "blob" }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
      }
    },
    selectNoticeCategory(noticecategoryId) {
      this.selectedNoticeCategory = noticecategoryId;
    },
    async fetchNoticeCategorys() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/NoticeCategory/notice-category-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.noticeCategorys = Array.isArray(data.data) ? data.data : [];
          console.log(this.noticeCategorys);
        }
      } catch (error) {
        console.error("Error fetching notice Categorys:", error);
      }
    },
    selectFixStatus(fixstatus) {
      this.selectedFixStatus = fixstatus;
    },
    goToNoticeList() {
      this.$router.push({ path: "/notices/list" });
    },
    async saveNotice() {
      // async 키워드 추가
      if (this.checkFields()) {
        try {
          // QuillEditor에서 HTML을 가져옴
          const quillEditor =
            this.$refs.content.$el.querySelector(".ql-editor");

          // 이미지 태그에서 src 속성 추출
          const imgTags = quillEditor.querySelectorAll("img");
          const imageSrcArray = [];

          // 이미지가 있는 경우 처리
          for (const img of imgTags) {
            const imgSrc = img.getAttribute("src");

            // base64 이미지를 처리하는 경우
            if (imgSrc.startsWith("data:image")) {
              const uniqueFileName = `image_${new Date().getTime()}.png`; // 파일명에 타임스탬프 추가
              const imageFile = this.dataURLtoFile(imgSrc, uniqueFileName); // base64를 파일로 변환
              const imageUrl = await this.uploadImage(imageFile); // 이미지 업로드

              if (imageUrl) {
                img.setAttribute("src", imageUrl); // 업로드된 URL로 이미지 src 변경
              }
            }
            imageSrcArray.push(imgSrc); // src 값을 배열에 추가
          }

          // HTML 콘텐츠를 다시 가져옴 (이미지 URL로 변경된 상태)
          const updatedContent = quillEditor.innerHTML;

          // 준비된 데이터를 payload로 구성
          // const payload = {
          //   NoticeId: this.noticeId,
          //   FixYN: this.selectedFixStatus,
          //   CategoryId: this.selectedNoticeCategory,
          //   NoticeSubject: this.noticeSubject,
          //   NoticeSubjectColor: this.titleColor,
          //   NoticeContent: updatedContent, // 이미지 URL로 대체된 HTML 콘텐츠
          //   LoginUserId: this.userId,
          //   // images: imageSrcArray, // 이미지 src 리스트를 함께 보냄 (선택 사항)
          // };

          const formData = new FormData();
          formData.append("NoticeId", this.id);
          formData.append("FixYN", this.selectedFixStatus);
          formData.append("CategoryId", this.selectedNoticeCategory);
          formData.append("NoticeSubject", this.noticeSubject);
          formData.append("NoticeSubjectColor", this.titleColor);
          formData.append("NoticeContent", updatedContent);
          formData.append("LoginUserId", this.userId);
          formData.append(
            "NoticeChangeReasons",
            JSON.stringify(this.changeReasons)
          );

          // 기존 첨부파일 목록 (파일 이름만 전달)
          for (let i = 0; i < this.attachedFileList.length; i++) {
            formData.append("existingFiles", this.attachedFileList[i]); // 기존 파일 이름을 폼 데이터에 추가
          }

          // 새로 업로드된 파일 목록 (실제 파일 객체 전달)
          for (let i = 0; i < this.uploadedFiles.length; i++) {
            formData.append("files", this.uploadedFiles[i]); // 새로 업로드된 파일을 폼 데이터에 추가
          }

          // API에 데이터 전송
          const response = await apiClient.post(
            "/api/Notice/notice-edit",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
            //payload
          );
          if (response.status === 200) {
            this.toast.success("공지사항이 성공적으로 수정되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToNoticeList();
          }
        } catch (error) {
          console.error("Error saving notice data:", error);
          this.toast.error("공지사항 수정 중 오류가 발생했습니다.", {
            position: "top-center",
            timeout: 1500,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true,
            closeButton: false,
          });
        }
      }
    },
    // base64 데이터를 파일로 변환하는 함수
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // 이미지 파일을 서버에 업로드하는 함수
    async uploadImage(imageFile) {
      const formData = new FormData();
      formData.append("image", imageFile);

      try {
        const response = await apiClient.post(
          "/api/Notice/notice-image-upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.status === 200) {
          return response.data.imageUrls[0]; // 서버에서 반환된 이미지 URL
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }

      return null; // 이미지 업로드 실패 시 null 반환
    },
    checkFields() {
      if (!this.selectedFixStatus) {
        this.toast.error("상단고정 유무 상태를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        return false;
      }
      if (!this.selectedNoticeCategory) {
        this.toast.error("공지사항 분류를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.noticeSubject) {
        this.$refs.noticeSubject.focus();
        this.toast.error("공지사항 제목을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      // QuillEditor 내용 체크 (HTML 태그 제거 후 공백 체크)
      const quillEditor = this.$refs.content.$el.querySelector(".ql-editor");
      const editorContent = quillEditor.innerHTML
        .replace(/<(.|\n)*?>/g, "")
        .trim();

      if (!editorContent) {
        this.$refs.content.$el.querySelector(".ql-editor").focus(); // QuillEditor 포커스 설정
        this.toast.error("공지사항 내용을 입력해주세요.", {
          position: "top-center",
          timeout: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          closeButton: false,
        });
        return false;
      }
      return true;
    },

    async fetchNoticeData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post("/api/Notice/notice-info", {
          NoticeId: this.id,
          LoginUserId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.originalData = { ...data.data[0] }; // 첫 번째 데이터만 복사해 저장

          this.noticeInfo = Array.isArray(data.data) ? data.data : [];

          this.noticeId = this.noticeInfo[0].noticeId;
          this.selectedFixStatus = this.noticeInfo[0].fixYN;
          this.selectedNoticeCategory = this.noticeInfo[0].categoryId;
          this.noticeSubject = this.noticeInfo[0].noticeSubject;
          this.titleColor = this.noticeInfo[0].noticeSubjectColor;

          // 에디터에 들어갈 내용은 content로 저장해 둡니다
          this.content = this.noticeInfo[0].noticeContent;

          // attachFiles 데이터를 ','로 구분하여 배열로 저장
          if (this.noticeInfo[0].attachFiles) {
            this.attachedFileList = this.noticeInfo[0].attachFiles.split(",");
          }

          // // Quill 에디터가 이미 준비된 상태이면 바로 반영
          if (this.editorReady) {
            this.setContentToEditor();
          }
        }
      } catch (error) {
        console.error("Error fetching manager info data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    onEditorReady() {
      this.editorReady = true; // 에디터 준비 완료

      if (this.content) {
        this.setContentToEditor(); // 에디터가 준비되면 바로 content 삽입
      }
    },
    setContentToEditor() {
      this.$nextTick(() => {
        const quillEditor = this.$refs.content.$el.querySelector(".ql-editor");

        if (quillEditor) {
          quillEditor.innerHTML = this.content;
        } else {
          console.log("Quill editor is not ready yet.");
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchNoticeCategorys(); // 공지사항 카테고리 목록을 먼저 가져옴
      this.fetchNoticeData(); // 데이터 로드
    });
  },
};
</script>

<style scoped>
.custom-quill-editor >>> .ql-editor {
  line-height: normal; /* 기본적인 라인 높이로 설정 */
  white-space: normal; /* 줄바꿈이 발생할 때 적절히 처리되도록 설정 */
  overflow-y: auto; /* 스크롤이 생길 수 있도록 강제 설정 */
  min-height: 550px; /* 에디터의 최소 높이 */
  max-height: 550px; /* 에디터의 최대 높이 */
  cursor: text; /* 텍스트 커서를 강제 설정 */
}

.custom-quill-editor >>> .ql-editor p {
  margin: 0;
}
/* 이미지가 부모 컨테이너의 너비를 넘지 않도록 설정 */
.custom-quill-editor img {
  max-width: 100%; /* 이미지를 부모 컨테이너에 맞게 축소 */
  height: auto; /* 이미지 비율을 유지하면서 크기 조정 */
  display: block; /* 이미지 주위에 공간이 없도록 block으로 설정 */
}

/* 색상 선택기의 기본 회색 배경 제거 및 커스터마이징 */
.custom-color-input {
  border: none; /* 기본 테두리 제거 */
  background: transparent; /* 배경 투명하게 */
  width: 30px; /* 너비 지정 */
  height: 30px; /* 높이 지정 */
  cursor: pointer; /* 커서 변경 */
  padding: 0;
}

.custom-color-input::-webkit-color-swatch {
  border: none; /* 기본 테두리 제거 */
}

.custom-color-input::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-box {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1px solid #ccc;
  margin-right: 5px;
}
</style>
