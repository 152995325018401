<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공지사항 목록</div>
        <div class="description">
          <span v-if="userType === 'M'">공지사항을 조회하고 관리합니다.</span>
          <span v-else-if="userType === 'C'"
            >공지사항을 조회할 수 있습니다.</span
          >
        </div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr style="display: none">
                  <th>상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'Y' }"
                        @click="updateStatus('Y')"
                      >
                        삭제
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        미삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="noticeSubject">제목</option>
                        >
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalNotices }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span>건씩</span>
            </div>
            <button
              type="button"
              v-if="userType === 'M'"
              @click="goToNoticeAdd"
            >
              등록하기
            </button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="noticeList.length > 0 || fixnoticeList.length"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col v-if="userType === 'M'" style="width: 5%" />
                  <col style="width: 7%" />
                  <col style="width: 10%" />
                  <col />
                  <col style="width: 18%" />
                  <col style="width: 15%" v-if="userType === 'M'" />
                  <!-- <col style="width: 10%" /> -->
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th v-if="userType === 'M'">
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">분류</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">제목</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">작성일시</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th v-if="userType === 'M'">
                      <a href="###">작성자</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <!-- <th>상태</th> -->
                  </tr>
                </thead>
                <tbody>
                  <!-- 상단고정 공지사항 -->
                  <tr
                    v-for="fixnotice in paginatedFixNotices"
                    :key="fixnotice.noticeId"
                  >
                    <td v-if="userType === 'M'"></td>
                    <td class="no_mobile"><b>고정</b></td>
                    <td class="seat o3">
                      <span class="only_mobile">분류 : </span>
                      <p class="long_p">
                        {{ fixnotice.categoryName }}
                      </p>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="board_titlebox">
                        <router-link
                          :to="noticeLink(fixnotice.noticeId)"
                          class="center_td"
                        >
                          <p
                            class="text_bold long_p"
                            :style="{ color: fixnotice.noticeSubjectColor }"
                          >
                            <b>{{ fixnotice.noticeSubject }}</b>
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="name o3 o_br">
                      <span class="only_mobile">작성일시 : </span>
                      <span>{{
                        fixnotice.createdDtm ? fixnotice.createdDtm : "미정"
                      }}</span>
                    </td>
                    <td class="name o5" v-if="userType === 'M'">
                      <span class="only_mobile">작성자 : </span>
                      <span>{{ fixnotice.createdUserName }}</span>
                    </td>
                    <!-- <td class="center_td o_stat">
                      <div :class="getStatusClass(fixnotice.delYN)">
                        <span>{{ getStatusText(fixnotice.delYN) }}</span>
                      </div>
                    </td> -->
                  </tr>
                  <!-- 일반 공지사항 -->
                  <tr v-for="notice in paginatedNotices" :key="notice.noticeId">
                    <td v-if="userType === 'M'">
                      <input
                        type="checkbox"
                        @change="toggleSelection(notice.noticeId)"
                        :checked="selectedNotices.includes(notice.noticeId)"
                      />
                    </td>
                    <td class="no_mobile">{{ notice.no }}</td>
                    <td class="seat o3">
                      <span class="only_mobile">분류 : </span>
                      <p class="long_p">
                        {{ notice.categoryName }}
                      </p>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="board_titlebox">
                        <router-link
                          :to="noticeLink(notice.noticeId)"
                          class="center_td"
                        >
                          <p
                            class="text_bold long_p"
                            :style="{ color: notice.noticeSubjectColor }"
                          >
                            <b>{{ notice.noticeSubject }}</b>

                            <!-- 상단고정 아이콘 -->
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="name o5">
                      <span class="only_mobile">작성일시 : </span>
                      <span>{{
                        notice.createdDtm ? notice.createdDtm : "미정"
                      }}</span>
                    </td>
                    <td class="name o5" v-if="userType === 'M'">
                      <span class="only_mobile">작성자 : </span>
                      <span>{{ notice.createdUserName }}</span>
                    </td>
                    <!-- <td class="center_td o_stat">
                      <div :class="getStatusClass(notice.delYN)">
                        <span>{{ getStatusText(notice.delYN) }}</span>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" v-if="userType === 'M'" />
                  <col style="width: 7%" />
                  <col style="width: 11%" />
                  <col />
                  <col style="width: 18%" />
                  <col style="width: 15%" v-if="userType === 'M'" />
                  <!-- <col style="width: 10%" /> -->
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th v-if="userType === 'M'">
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">분류</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">제목</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">작성일시</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th v-if="userType === 'M'">
                      <a href="###">작성자</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <!-- <th>상태</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="6">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div v-if="userType === 'M'">
              <a @click.prevent="deleteSelected">삭제</a>
              <a @click.prevent="nodeleteSelected" style="display: none"
                >미삭제</a
              >
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
      <!-- 삼성인터넷브라우저에서 안되서 원인 파악하다가 스타일문제로 확인! 임시방편임 -->
      <div style="margin-top: 200px"></div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "NoticesList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      fixnoticeList: [], // 상단 공지사항 목록 데이터
      noticeList: [], // 공지사항 목록 데이터를 저장할 배열
      selectedNotices: [], // 선택된 담당자들의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "noticeSubject", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchStatus: "N", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalNotices: 0, // 총 공지사항 수
    };
  },
  computed: {
    noticeLink() {
      return (noticeId) => {
        return this.userType === "M"
          ? { name: "NoticesView", params: { id: noticeId } }
          : { name: "NoticesCompanyView", params: { id: noticeId } };
      };
    },
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    paginatedFixNotices() {
      if (!Array.isArray(this.fixnoticeList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      return this.fixnoticeList.slice(start, end);
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedNotices() {
      if (!Array.isArray(this.noticeList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      return this.noticeList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalNotices / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },

  methods: {
    updateStatus(status) {
      this.SearchStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.noticeList = []; //
      this.fetchData();
    },
    getFormattedStartDate(startDate) {
      if (!startDate) {
        return "";
      }

      // 날짜 문자열을 '^'로 분리
      const dates = startDate.split("^");

      // 첫 번째 날짜는 그대로 표시하고, 나머지 날짜가 있다면 "(선택가능)" 추가
      if (dates.length > 1) {
        return `${dates[0]} (선택가능)`;
      }

      return dates[0]; // 첫 번째 날짜만 반환
    },

    // 상태에 따른 문구 반환
    getStatusText(status) {
      switch (status) {
        case "N":
          return "미삭제";
        case "Y":
          return "삭제";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "N":
          return "stat back_green"; // 미삭제
        case "Y":
          return "stat back_red"; // 삭제
        default:
          return "stat back_default"; // 기본 값
      }
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.noticeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },

    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedNotices = this.noticeList.map((notice) => notice.noticeId);
      } else {
        this.selectedNotices = [];
      }
    },
    toggleSelection(noticeId) {
      const index = this.selectedNotices.indexOf(noticeId);

      if (index > -1) {
        this.selectedNotices.splice(index, 1);
      } else {
        this.selectedNotices.push(noticeId); // 기존 코드를 이 줄로 수정
      }
    },
    nodeleteSelected() {
      if (this.selectedNotices.length === 0) {
        this.toast.error(
          "원하시는 미삭제 처리할 공지사항 리스트를 선택해주세요.",
          {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          }
        );
        return;
      }
      if (confirm("선택된 공지사항 항목을 미삭제 로 변경하시겠습니까?")) {
        this.NoDeleteNotice(this.selectedNotices);
      }
    },
    async NoDeleteNotice(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Notice/notice-no-delete", {
            UserId: id,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to no delete notice for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedNotices = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error no deleting notice:", error);
      }
    },
    deleteSelected() {
      if (this.selectedNotices.length === 0) {
        this.toast.error("원하시는 삭제 공지사항 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (
        confirm(
          "삭제 시 복구 불가능합니다. 선택된 공지사항 항목을 삭제하시겠습니까?"
        )
      ) {
        this.DeleteNotice(this.selectedNotices);
      }
    },
    async DeleteNotice(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Notice/notice-delete", {
            NoticeId: id,
            LoginUserId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to delete notice for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedNotices = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error deleting notice:", error);
      }
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.noticeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.noticeList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.noticeList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.noticeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.noticeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.noticeList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    goToNoticeAdd() {
      this.$router.push({ path: "/notices/add" });
    },
    async fetchData() {
      try {
        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          //OrderType: this.OrderType,
          SearchActiveStatus: this.SearchStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          LoginUserId: localStorage.getItem("userid"),
        };

        const response = await apiClient.post(
          "/api/Notice/all-notice-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          this.noticeList = Array.isArray(data.data) ? data.data : [];
          console.log("---notice---" + this.noticeList);
          this.totalNotices = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching notices list data:", error);
      }
    },
    async fetchFixData() {
      try {
        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          //OrderType: this.OrderType,
          SearchActiveStatus: this.SearchStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          LoginUserId: localStorage.getItem("userid"),
        };

        const response = await apiClient.post(
          "/api/Notice/all-fix-notice-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          this.fixnoticeList = Array.isArray(data.data) ? data.data : [];
          console.log("---fixnoticeList---" + this.fixnoticeList);
        }
      } catch (error) {
        console.error("Error fetching notices list data:", error);
      }
    },
  },
  mounted() {
    this.fetchFixData();
    this.fetchData();
  },
};
</script>

<style></style>
