<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공지사항 상세보기</div>
        <div class="description">공지사항은 필독 해주세요.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info onlyview">
            <table>
              <colgroup class="no_mobile">
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th class="no_mobile">공지사항 제목</th>
                  <td class="notice_title">
                    <div class="w_250" :style="{ color: this.titleColor }">
                      <b>{{ this.noticeSubject }}</b>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="no_mobile">내용</th>
                  <td class="board_content notice_content">
                    <!-- HTML 형식의 내용을 v-html로 렌더링 -->
                    <div v-html="content" class="content-render"></div>
                  </td>
                </tr>
                <tr>
                  <th class="no_mobile">첨부파일</th>
                  <td class="notice_files">
                    <ul v-if="attachedFileList.length > 0" class="files">
                      <!-- 합쳐진 리스트로 파일 리스트를 보여줌 -->
                      <li
                        v-for="(file, index) in attachedFileList"
                        :key="index"
                      >
                        <button @click="downloadFile(file)">{{ file }}</button>
                      </li>
                    </ul>
                    <p v-else>첨부파일 없음</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" @click="goToNoticeList">목록</button>
          </div>
        </article>
      </div>
      <!-- 삼성인터넷브라우저에서 안되서 원인 파악하다가 스타일문제로 확인! 임시방편임 -->
      <div style="margin-top: 100px"></div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "NoticesCompanyView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      noticeId: "",
      noticeCategorys: [], // PM 담당자 목록을 저장할 배열
      selectedNoticeCategory: "", // 선택된 공지사항 카테고리 값
      noticeSubject: "", // 공지사항 제목
      titleColor: "#000000", // 제목의 기본 색상 (검정색)
      content: "", // 에디터 내용
      attachedFileList: [], // 첨부파일 리스트를 저장할 배열
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    // content의 줄바꿈을 <br> 태그로 변환하여 HTML로 표시할 수 있게 함
    formattedContent() {
      return this.content ? this.content.replace(/\n/g, "<br>") : "";
    },
  },
  methods: {
    async downloadFile(fileName) {
      try {
        const response = await apiClient.get(
          `/api/Notice/download-file?fileName=${fileName}`,
          { responseType: "blob" }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
      }
    },
    goToNoticeList() {
      this.$router.push({ path: "/notices/list" });
    },
    async fetchNoticeData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post("/api/Notice/notice-info", {
          NoticeId: this.id,
          LoginUserId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.noticeInfo = Array.isArray(data.data) ? data.data : [];

          this.noticeId = this.noticeInfo[0].noticeId;
          this.noticeSubject = this.noticeInfo[0].noticeSubject;
          this.titleColor = this.noticeInfo[0].noticeSubjectColor;
          this.content = this.noticeInfo[0].noticeContent;
          // attachFiles 데이터를 ','로 구분하여 배열로 저장
          if (this.noticeInfo[0].attachFiles) {
            this.attachedFileList = this.noticeInfo[0].attachFiles.split(",");
          }
        }
      } catch (error) {
        console.error("Error fetching manager info data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
  },
  mounted() {
    this.fetchNoticeData(); // 데이터 로드
  },
};
</script>
<style scoped>
p {
  margin: 10px 0;
  line-height: 1.5;
}
</style>
